/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const FilterSortAsc: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="filter-sort-asc" ref={ref}  {...props}>
            <path
                d={isLarge ? "M400 360C400 371 391 380 380 380H120C109 380 100 371 100 360C100 354.4 102.2 349.4 105.8 345.8L200 251.8V80C200 69 209 60 220 60C225.6 60 230.6 62.2 234.2 65.8L294.2000000000001 125.8C297.8 129.4 300 134.4 300 140V251.8L394.2000000000001 346C397.8 349.4 400 354.4 400 360zM94.2 194.2C90.6 197.8 85.6 200 80 200C74.4 200 69.4 197.8 65.8 194.2L5.8 134.2000000000001C2.2 130.6 0 125.6 0 120C0 109 9 100 20 100C25.6 100 30.6 102.2 34.2 105.8L60 131.8V20C60 9 69 0 80 0C91 0 100 9 100 20V131.8L125.8 106C129.4 102.2 134.4 100 140 100C151 100 160 109 160 120C160 125.6 157.8 130.6 154.2 134.2000000000001L94.2 194.2z" : "M320 280C320 274.4 317.8 269.4 314.2000000000001 266L240 191.8V120C240 114.4 237.8 109.4 234.2 105.8L194.2 65.8C190.6 62.2 185.6 60 180 60C169 60 160 69 160 80V191.8L85.8 265.8C82.2 269.4 80 274.4 80 280C80 291 89 300 100 300H300C311 300 320 291 320 280zM100 79.898C94.4 79.898 89.4 82.094 85.8 85.69L80 91.484V19.972C80 8.986 71 -0.002 60 -0.002C49 -0.002 40 8.986 40 19.972V91.682L34.2 85.89C30.6 82.294 25.6 80.098 20 80.098C9 80.098 0 89.086 0 100.072C0 105.666 2.2 110.66 5.8 114.254L45.8 154.2048C49.4 157.8002 54.4 159.9976 60 159.9976C65.6 159.9976 70.6 157.8002 74.2 154.2048L114.2 114.254C117.8 110.66 120 105.666 120 100.072C120 88.886 111 79.898 100 79.898z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
FilterSortAsc.defaultProps = {
    size: IconSize.STANDARD,
};
FilterSortAsc.displayName = `Blueprint5.Icon.FilterSortAsc`;
export default FilterSortAsc;
