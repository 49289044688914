import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Intent,
	Navbar,
	InputGroup,
} from '@blueprintjs/core';

class CustomerDeliveryAddressPickerDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			filter: '',
			delivery_addresses: props.customer.delivery_addresses,
		};
	}
	
	render() {
		const filter = this.state.filter.toLowerCase();
		let filtered_addresses = [];
		for (let i=0; i<this.state.delivery_addresses.length; i++) {
			if (this.state.filter.length === 0 || this.state.delivery_addresses[i].toLowerCase().indexOf(filter) !== -1) {
				filtered_addresses.push({ idx: i, delivery_address: this.state.delivery_addresses[i] });
			}
		}
		/*filtered_addresses = [];
		for (let i=0; i<50; i++) {
			filtered_addresses.push(
				'test '.repeat(Math.floor(Math.random() * 20 + 5))
			);
			//filtered_addresses = [...filtered_addresses, ...filtered_addresses];
		}*/
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog(undefined, this.state.delivery_addresses)}
			className='customer-delivery-address-picker-dialog'>
			<div className='flex flex-col flex-grow max-h-full'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>{'Izberi naslov'}</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog(undefined, this.state.delivery_addresses)} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY + ' flex flex-col'}>
					<div className='relative flex-1'>
						<InputGroup
							className='mb-2'
							value={this.state.filter}
							onChange={event => {
								this.setState({ filter: event.target.value });
							}} />
						
						<div className='overflow-y-auto absolute inset-0' style={{ top: 36 }}>
							<div className='grid grid-cols-5 gap-1' style={{ gridTemplateColumns: '24px 1fr 8px 24px 1fr' }}>
								{filtered_addresses.map((x, idx) => <React.Fragment key={idx}>
									<Button
										icon='trash'
										intent='danger'
										minimal={true}
										small={true}
										onClick={() => {
											const delivery_addresses = [...this.state.delivery_addresses];
											delivery_addresses.splice(x.idx, 1);
											
											this.setState({
												delivery_addresses,
											});
										}}
										className='justify-center items-center self-start' />
									<Button
										onClick={() => this.props.closeDialog(
											x.delivery_address,
											this.state.delivery_addresses
										)}
										className='justify-start items-start'>
										{x.delivery_address.split('\n').map((line, line_idx) => <div key={line_idx}>
											{ line }
										</div>)}
									</Button>
									{idx % 2 == 0 ? <div /> : null}
								</React.Fragment>)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
CustomerDeliveryAddressPickerDialog.propTypes = {
	closeDialog: PropTypes.func,
	customer: PropTypes.object,
};

export default CustomerDeliveryAddressPickerDialog;
