/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const MapMarker: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="map-marker" ref={ref}  {...props}>
            <path
                d={isLarge ? "M200 400C122.6 400 60 340.4 60 266.6C60 193 200 0 200 0C200 0 340 193 340 266.6C340 340.2 277.2 400 200 400zM200 200C166.8 200 140 226.8 140 260C140 293.2 166.8 320 200 320C233.2 320 260 293.2 260 260C260 226.8 233.2 200 200 200z" : "M159.9998 320C99.1998 320 49.7998 272.2 49.7998 213.4C49.7998 154.4 159.9998 0 159.9998 0C159.9998 0 270.2 154.4 270.2 213.4C270 272.2 220.8 320 159.9998 320zM159.9998 160C132.3998 160 109.9998 182.4 109.9998 210C109.9998 237.6 132.3998 260 159.9998 260C187.5998 260 210 237.6 210 210C210 182.4 187.5998 160 159.9998 160z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
MapMarker.defaultProps = {
    size: IconSize.STANDARD,
};
MapMarker.displayName = `Blueprint5.Icon.MapMarker`;
export default MapMarker;
