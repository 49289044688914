import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	HTMLSelect,
	Icon,
	InputGroup,
	MenuItem,
	Navbar,
	Checkbox,
	Tabs,
	Tab,
	TextArea,
	Alert,
	Intent,
} from '@blueprintjs/core';
import { DateInput3, IDateFormatProps } from '@blueprintjs/datetime2';
import { Suggest } from '@blueprintjs/select';

import moment from 'moment';
import formatISO from 'date-fns/formatISO';

import XLSX from 'xlsx';

import TextWithTooltip                                   from '../TextWithTooltip';
import CustomerSelect                                    from '../CustomerSelect';
import SelectCustomerDialog                              from '../reservations/SelectCustomerDialog';
import { parseFloatLocal }                               from '../../numbers';
import Documents                                         from '../Documents';
import PriceHelper                                       from '../../helpers/PriceHelper';
import { AppToaster }                                    from '../AppToaster';
import { loadLastStockDataByDate }                       from '../../api/Warehouses';
import { loadDateFnsLocale }                             from '../../utils';
import CustomerDeliveryAddressPickerDialog               from './CustomerDeliveryAddressPickerDialog';

const Types = {
	'delivery': 'Dobavnica',
	'return':   'Vračilo v zalogo',
};

class InvoiceItemViewModel {
	constructor(
		single_purchase_price_without_vat,
		single_purchase_price_with_vat,
		single_purchase_price_with_discount_without_vat,
		single_purchase_price_with_discount_with_vat,
		purchase_price_without_vat,
		purchase_price_with_vat,
		price_with_vat,
		invoice_item
	) {
		this.single_purchase_price_without_vat               = single_purchase_price_without_vat;
		this.single_purchase_price_with_vat                  = single_purchase_price_with_vat;
		this.single_purchase_price_with_discount_without_vat = single_purchase_price_with_discount_without_vat;
		this.single_purchase_price_with_discount_with_vat    = single_purchase_price_with_discount_with_vat;
		this.purchase_price_without_vat                      = purchase_price_without_vat;
		this.purchase_price_with_vat                         = purchase_price_with_vat;
		this.price_with_vat                                  = price_with_vat;
		this.invoice_item                                    = invoice_item;
		
		this.quantity_formatted = this.formatNumber(
			this.invoice_item.quantity,
			this.invoice_item.item_measuring_unit_decimal_digit_count
		);
		this.purchase_discount_formatted                               = this.formatNumber(this.invoice_item.purchase_discount);
		this.single_purchase_price_without_vat_formatted               = this.formatNumber(this.single_purchase_price_without_vat, 4);
		this.single_purchase_price_with_vat_formatted                  = this.formatNumber(this.single_purchase_price_with_vat, 4);
		this.single_purchase_price_with_discount_without_vat_formatted = this.formatNumber(this.single_purchase_price_with_discount_without_vat, 4);
		this.single_purchase_price_with_discount_with_vat_formatted    = this.formatNumber(this.single_purchase_price_with_discount_with_vat, 4);
		this.purchase_price_without_vat_formatted                      = this.formatNumber(this.purchase_price_without_vat);
		this.purchase_price_with_vat_formatted                         = this.formatNumber(this.purchase_price_with_vat);
		this.price_with_vat_formatted                                  = this.formatNumber(this.price_with_vat);
		
		this.price           = this.price.bind(this);
		//this.priceWithoutVat = this.priceWithoutVat.bind(this);
		
		this.original_price_with_vat             = price_with_vat;
		this.original_price_with_vat_formatted   = this.formatNumber(this.original_price_with_vat);
		this.price_list_price_with_vat           = price_with_vat;
		this.price_list_price_with_vat_formatted = this.formatNumber(this.price_list_price_with_vat);
	}
	
	static FromInvoiceItem(items, invoice_item, tax_rates) {
		const item             = items[invoice_item.id_item];
		const price_list_items = item === undefined ? {} : item.price_lists;
		const price_list       = invoice_item.id_item_price_list === null ? undefined : price_list_items[invoice_item.id_item_price_list];
		const price            = (invoice_item.price !== undefined ? invoice_item.price :
			(price_list === undefined ? 0 : price_list.price));
		
		const quantity         = invoice_item.quantity;
		const discount         = invoice_item.purchase_discount;
		
		const purchase_price_without_vat = invoice_item.purchase_price_without_vat;
console.log({ invoice_item });
		const purchase_vat               = invoice_item.purchase_id_tax_rate === null || invoice_item.purchase_id_tax_rate == -1 ? 0 : tax_rates[invoice_item.purchase_id_tax_rate].value;
		
		return new InvoiceItemViewModel(
			purchase_price_without_vat,
			purchase_price_without_vat *  (1 + purchase_vat / 100),
			purchase_price_without_vat * (1 - discount / 100),
			purchase_price_without_vat * (1 - discount / 100) * (1 + purchase_vat / 100),
			purchase_price_without_vat * quantity * (1 - discount / 100),
			purchase_price_without_vat * quantity * (1 - discount / 100) * (1 + purchase_vat / 100),
			price,
			invoice_item
		);
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	price(priceList) {
		if (this.invoice_item.id_item_price_list === null) return 0;
		return priceList[this.invoice_item.id_item_price_list].price;
	}
	/*priceWithoutVat(priceList, taxRates) {
		if (this.invoice_item.id_item_price_list === null) return 0;
		
		const price_list = priceList[this.invoice_item.id_item_price_list];
		const vat        = (price_list === undefined ? 0 : taxRates[price_list.id_tax_rate].value);
		return Math.round(price_list.price / (1 + vat / 100) * 100) / 100;
	}*/
}

const ItemsListItem = React.memo(props => {
	const item = props.item;
	const idx  = props.idx;
	
	let vat = item.invoice_item.id_tax_rate === null || item.invoice_item.id_tax_rate == -1 ?
		0 : props.tax_rates[item.invoice_item.id_tax_rate].value;
	
	let column_idx = 1;
	
	const ref = React.createRef();
	
	return props.read_only ?
		<>
			<div className='items-table-col text-center' style={{ gridColumn: column_idx++ }}>
				<Button
					icon='trash'
					intent='danger'
					minimal={true}
					small={true}
					disabled={props.read_only}
					onClick={() => {}} />
			</div>
			<div className='items-table-col' style={{ gridColumn: column_idx++ }}>
				{ item.invoice_item.internal_code + ' • ' + item.invoice_item.item_title }
			</div>
			<div className='items-table-col text-center' style={{ gridColumn: column_idx++ }}>
				{ item.quantity_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ item.single_purchase_price_without_vat_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ item.single_purchase_price_with_vat_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ item.purchase_discount_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ item.single_purchase_price_with_discount_without_vat_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ item.single_purchase_price_with_discount_with_vat_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ item.purchase_price_without_vat_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ item.purchase_price_with_vat_formatted }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ props.formatNumber(vat) }
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{ props.stock_state_item === undefined ? '' : props.stock_state_item.count }
			</div>
		</>
		:
		<>
			<div className='items-table-col text-center' style={{ gridColumn: column_idx++ }}>
				{ item.invoice_item.id_item == -1 ? null :
					<Button
						icon='trash'
						intent='danger'
						minimal={true}
						small={true}
						onClick={() => {
							props.deleteItem(props.idx);
						}} />
				}
			</div>
			<div className='items-table-col tight service-suggest' style={{ gridColumn: column_idx++ }}>
				{item.invoice_item.item_title !== null ?
					<div className={'pl-2 text-selectable ' + (item.invoice_item.id_item === null ? 'text-green-500' : '')}>
						{ item.invoice_item.internal_code + ' • ' + item.invoice_item.item_title }
					</div>
					:
					<Suggest
						itemPredicate={(query, item, idx, exactMatch) => {
							const trimmed_query = query.trim();
							if (trimmed_query.length < 2) return false;
							
							if (trimmed_query.length == 2) {
								return (item.internal_code.toLowerCase().indexOf(trimmed_query.toLowerCase()) == 0);
							}
							return (
								item.title.toLowerCase().indexOf(trimmed_query.toLowerCase()) > -1 ||
								item.internal_code.toLowerCase().indexOf(trimmed_query.toLowerCase()) > -1 ||
								(item.ean !== null && item.ean.length > 0 && item.ean == trimmed_query)
							);
						}}
						itemRenderer={(item, { handleClick, modifiers }) => {
							return <MenuItem
								active={modifiers.active}
								key={item.internal_code}
								onClick={handleClick}
								text={item.internal_code + ' • ' + item.title}
								label={item.ean} />;
						}}
						items={Object.values(props.items)}
						onItemSelect={item => {
							props.addItem(item, idx);
							
							if (ref !== undefined && ref !== null && ref.current !== undefined && ref.current !== null) {
								const curr = ref.current;
								curr.focus();
								setTimeout(() => {
									curr.select();
								}, 200);
							}
						}}
						openOnKeyDown={true}
						popoverProps={{
							targetProps: { style: { display: 'block' } },
							minimal: true,
						}}
						fill={true}
						selectedItem={item.invoice_item.id_item == -1 ? null : props.items[item.invoice_item.id_item]}
						inputProps={{
							placeholder: 'Išči',
						}}
						inputValueRenderer={item => item.internal_code + ' • ' + item.title}
						resetOnSelect={true}
						disabled={props.disabled} />
				}
			</div>
			<div className='items-table-col text-center tight' style={{ gridColumn: column_idx++ }}>
				<input
					type='text'
					value={item.quantity_formatted}
					className='text-center'
					ref={ref}
					onChange={event => {
						let val = parseFloatLocal(event.target.value);
						if (Object.is(val, NaN)) {
							val = item.invoice_item.quantity;
						}
						
						props.changeInvoiceItemValue(
							item,
							idx,
							[ 'invoice_item', 'quantity' ],
							val,
							null,
							'quantity_formatted',
							event.target.value
						);
					}}
					onBlur={event => props.changeInvoiceItemValue(item, idx, 'refresh', null)}
					disabled={props.disabled} />
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				<input
					type='text'
					className='text-right'
					value={item.single_purchase_price_without_vat_formatted}
					onChange={event => {
						let val = parseFloatLocal(event.target.value);
						if (Object.is(val, NaN)) {
							val = item.single_purchase_price_without_vat;
						}
						
						props.changeInvoiceItemValue(
							item,
							idx,
							'single_purchase_price_without_vat',
							val,
							null,
							'single_purchase_price_without_vat_formatted',
							event.target.value
						);
					}}
					onBlur={event => props.changeInvoiceItemValue(item, idx, 'refresh', null)}
					disabled={props.disabled} />
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				<input
					type='text'
					className='text-right'
					value={item.single_purchase_price_with_vat_formatted}
					onChange={event => {
						let val = parseFloatLocal(event.target.value);
						if (Object.is(val, NaN)) {
							val = item.single_purchase_price_with_vat;
						}
						
						props.changeInvoiceItemValue(
							item,
							idx,
							'single_purchase_price_with_vat',
							val,
							null,
							'single_purchase_price_with_vat_formatted',
							event.target.value
						);
					}}
					onBlur={event => props.changeInvoiceItemValue(item, idx, 'refresh', null)}
					disabled={props.disabled} />
			</div>
			<div className='items-table-col tight text-right' style={{ gridColumn: column_idx++ }}>
				<input
					type='text'
					className='text-right'
					value={item.purchase_discount_formatted}
					onChange={event => {
						let val = parseFloatLocal(event.target.value);
						if (Object.is(val, NaN)) {
							val = item.invoice_item.purchase_discount;
						}
						
						props.changeInvoiceItemValue(
							item,
							idx,
							[ 'invoice_item', 'purchase_discount' ],
							val,
							null,
							'purchase_discount_formatted',
							event.target.value
						);
					}}
					onBlur={event => props.changeInvoiceItemValue(item, idx, 'refresh', null)}
					disabled={props.disabled} />
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{item.single_purchase_price_with_discount_without_vat_formatted}
			</div>
			<div className='items-table-col text-right' style={{ gridColumn: column_idx++ }}>
				{item.single_purchase_price_with_discount_with_vat_formatted}
			</div>
			<div className='items-table-col tight text-right' style={{ gridColumn: column_idx++ }}>
				<input
					type='text'
					className='text-right'
					value={item.purchase_price_without_vat_formatted}
					onChange={event => {
						let val = parseFloatLocal(event.target.value);
						if (Object.is(val, NaN)) {
							val = item.purchase_price_without_vat;
						}
						
						props.changeInvoiceItemValue(
							item,
							idx,
							'purchase_price_without_vat',
							val,
							null,
							'purchase_price_without_vat_formatted',
							event.target.value
						);
					}}
					onBlur={event => props.changeInvoiceItemValue(item, idx, 'refresh', null)}
					disabled={props.disabled} />
			</div>
			<div className='items-table-col tight text-right' style={{ gridColumn: column_idx++ }}>
				<input
					type='text'
					className='text-right'
					value={item.purchase_price_with_vat_formatted}
					onChange={event => {
						let val = parseFloatLocal(event.target.value);
						if (Object.is(val, NaN)) {
							val = item.purchase_price_with_vat;
						}
						
						props.changeInvoiceItemValue(
							item,
							idx,
							'purchase_price_with_vat',
							val,
							null,
							'purchase_price_with_vat_formatted',
							event.target.value
						);
					}}
					onBlur={event => props.changeInvoiceItemValue(item, idx, 'refresh', null)}
					disabled={props.disabled} />
			</div>
			<div className='items-table-col' style={{ gridColumn: column_idx++ }}>
				{item.invoice_item.id_item != -1 && item.invoice_item.id_item !== null ?
					<div>{
						item.invoice_item.id_tax_rate === null ||
						item.invoice_item.id_tax_rate === undefined ||
						props.tax_rates[item.invoice_item.id_tax_rate] === undefined ? null :
							props.formatNumber(props.tax_rates[item.invoice_item.id_tax_rate].value) + '% • ' + props.tax_rates[item.invoice_item.id_tax_rate].title
					}</div>
					:
					<HTMLSelect
						options={[
							{ label: '-- izberi --', value: -1 },
							...Object.keys(props.tax_rates)
								.sort((a, b) => props.tax_rates[b].value - props.tax_rates[a].value)
								.map(x => { return {
									label: props.formatNumber(props.tax_rates[x].value) + '% • ' + props.tax_rates[x].title,
									value: x,
								}; }),
						]}
						value={item.invoice_item.id_tax_rate}
						onChange={(e) => {
							props.changeInvoiceItemValue(
								item,
								idx,
								[ 'invoice_item', 'id_tax_rate' ],
								e.currentTarget.value,
								null,
								'',
								''
							);
						}}
						disabled={props.disabled} />
					}
			</div>
			<div className='items-table-col text-right' style={
				item.price_with_vat == item.original_price_with_vat ?
				{ gridColumn: column_idx++ }
				:
				{ gridColumn: column_idx++, backgroundColor: '#ffa7a7' }
			}>
				{ props.stock_state_item === undefined ? '' : props.stock_state_item.count }
			</div>
		</>;
});

class ItemsList extends Component {
	constructor(props) {
		super(props);
		
		this.setMainTableItemsSort      = this.setMainTableItemsSort     .bind(this);
		this.formatNumber               = this.formatNumber              .bind(this);
		this.addEmptyItemIfNeeded       = this.addEmptyItemIfNeeded      .bind(this);
		this.changeInvoiceItemValue     = this.changeInvoiceItemValue    .bind(this);
		this.addItem                    = this.addItem                   .bind(this);
		this.deleteItem                 = this.deleteItem                .bind(this);
		
		this.state = {
			main_table_items_sort_field: '',
			main_table_items_sort_direction: 'ASC',
		};
		
		this.main_table_refs = [];
	}
	
	setMainTableItemsSort(field) {
		//let direction = 'ASC';
		//if (this.state.main_table_items_sort_field == field) {
		//	direction = (this.state.main_table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		//}
		//
		//const ids = this.state.main_table_items_sort_ids;
		//ids.sort((a_key, b_key) => {
		//	const a = this.state.main_table_items[a_key][field];
		//	const b = this.state.main_table_items[b_key][field];
		//	
		//	return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		//});
		//
		//this.setState({
		//	main_table_items_sort_ids:       ids,
		//	main_table_items_sort_field:     field,
		//	main_table_items_sort_direction: direction,
		//});
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	addEmptyItemIfNeeded(document_items) {
		if (this.props.read_only) return;
		
		if (document_items.length == 0 || document_items[document_items.length - 1].invoice_item.id_item != -1) {
			const item = InvoiceItemViewModel.FromInvoiceItem(this.props.items, this.getEmptyInvoiceItem(), this.props.tax_rates);
			item.quantity = 1;
			document_items.push(item);
			
			this.props.setInvoiceItems(document_items);
		}
	}
	
	getEmptyInvoiceItem() {
		return {
			id_delivery_note_item: -1,
			id_delivery_note:      -1,
			id_item:                            -1,
			quantity:                            0,
			price:                               0,
			id_tax_rate:                        -1,
			purchase_price_without_vat:          0,
			purchase_id_tax_rate:               -1,
			purchase_discount:                   0,
			item_title:                          null,
			id_item_price_list:                 -1,
			item_measuring_unit_decimal_digit_count: 0,
			ean:                                '',
			internal_code:                      '',
		};
	}
	
	changeInvoiceItemValue(item, idx, field_name, val, document_items, formatted_field_name, formatted_field_value) {
		document_items = [...(document_items || this.props.document_items)];
		
		const new_item = {...document_items[idx]};
		if (Array.isArray(field_name)) {
			new_item[field_name[0]][field_name[1]] = val;
		}
		else {
			if (field_name != 'refresh') {
				new_item[field_name] = val;
			}
		}
		
		if (field_name == 'refresh') {
			// round quantity to item_measuring_unit_decimal_digit_count
			const quantity            = parseFloat(new_item.invoice_item.quantity);
			const decimal_digit_count = 2; //parseFloat(new_item.invoice_item.item_measuring_unit_decimal_digit_count);
			
			let decimal_factor = 10 * decimal_digit_count;
			if (decimal_factor == 0) decimal_factor = 1;
			
			new_item.invoice_item.quantity =
				Math.round(quantity * decimal_factor) / decimal_factor;
		}
		
		/*let vat = 0;
		if (item.invoice_item !== undefined &&
			item.invoice_item.id_item_price_list !== null &&
			item.invoice_item.id_item != -1
		) {
			vat = this.props.tax_rates[
				this.props.items[item.invoice_item.id_item].price_lists[item.invoice_item.id_item_price_list].id_tax_rate
			].value;
		}*/
		new_item.quantity_formatted                                        = new_item.invoice_item.quantity;
		new_item.purchase_discount_formatted                               = new_item.invoice_item.purchase_discount;
		new_item.single_purchase_price_with_vat_formatted                  = new_item.single_purchase_price_with_vat;
		new_item.purchase_price_with_vat_formatted                         = new_item.purchase_price_with_vat;
		new_item.purchase_price_without_vat_formatted                      = new_item.purchase_price_without_vat;
		new_item.single_purchase_price_without_vat_formatted               = new_item.single_purchase_price_without_vat;
		new_item.single_purchase_price_with_discount_without_vat_formatted = new_item.single_purchase_price_with_discount_without_vat;
		new_item.single_purchase_price_with_discount_with_vat_formatted    = new_item.single_purchase_price_with_discount_with_vat;
		new_item.price_with_vat_formatted                                  = new_item.price_with_vat;
		
		// set new price
		/*if (
			Array.isArray(field_name)             &&
			field_name.length == 2                &&
			field_name[0] == 'invoice_item'       &&
			field_name[1] == 'id_item_price_list'
		) {
			new_item.single_purchase_price_with_vat =
				this.props.items[item.invoice_item.id_item].price_lists[item.invoice_item.id_item_price_list].price;
		}*/
		
		const purchase_id_tax_rate  = new_item.invoice_item.purchase_id_tax_rate;
		const purchase_vat          = purchase_id_tax_rate === null || purchase_id_tax_rate === undefined || purchase_id_tax_rate == -1 ?
										0 : this.props.tax_rates[purchase_id_tax_rate].value;
		
		let purchase_price_without_vat = new_item.single_purchase_price_without_vat;
		if (field_name == 'single_purchase_price_with_vat') {
			purchase_price_without_vat = new_item.single_purchase_price_with_vat / (1 + purchase_vat / 100);
		}
		else if (field_name == 'purchase_price_with_vat') {
			purchase_price_without_vat = new_item.purchase_price_with_vat / new_item.invoice_item.quantity / (1 - new_item.invoice_item.purchase_discount / 100) / (1 + purchase_vat / 100);
		}
		else if (field_name == 'purchase_price_without_vat') {
			purchase_price_without_vat = new_item.purchase_price_without_vat / new_item.invoice_item.quantity / (1 - new_item.invoice_item.purchase_discount / 100);
		}
		
		// format discount
		if (formatted_field_name == 'purchase_discount_formatted') {
			new_item.purchase_discount_formatted = formatted_field_value;
		}
		else if (!Array.isArray(field_name) || field_name[1] != 'purchase_discount') {
			new_item.purchase_discount_formatted = this.formatNumber(new_item.invoice_item.purchase_discount);
		}
		
		// format quantity
		if (formatted_field_name == 'quantity_formatted') {
			new_item.quantity_formatted = formatted_field_value;
		}
		else if (!Array.isArray(field_name) || field_name[1] != 'quantity') {
			new_item.quantity_formatted = this.formatNumber(
				new_item.invoice_item.quantity,
				new_item.invoice_item.item_measuring_unit_decimal_digit_count
			);
		}
		
		// calculate and format single price without VAT
		if (formatted_field_name == 'single_purchase_price_without_vat_formatted') {
			new_item.single_purchase_price_without_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'single_purchase_price_without_vat') {
			new_item.single_purchase_price_without_vat = 
				purchase_price_without_vat;
			new_item.single_purchase_price_without_vat_formatted = this.formatNumber(new_item.single_purchase_price_without_vat, 4);
		}
		
		// calculate and format single price with VAT
		if (formatted_field_name == 'single_purchase_price_with_vat_formatted') {
			new_item.single_purchase_price_with_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'single_purchase_price_with_vat') {
			new_item.single_purchase_price_with_vat = 
				purchase_price_without_vat * 
				(1 + purchase_vat / 100);
			new_item.single_purchase_price_with_vat_formatted = this.formatNumber(new_item.single_purchase_price_with_vat, 4);
		}
		
		// calculate and format single price with discount without VAT
		if (formatted_field_name == 'single_purchase_price_with_discount_without_vat_formatted') {
			new_item.single_purchase_price_with_discount_without_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'single_purchase_price_with_discount_without_vat') {
			new_item.single_purchase_price_with_discount_without_vat = 
				purchase_price_without_vat * 
				(1 - new_item.invoice_item.purchase_discount / 100);
			new_item.single_purchase_price_with_discount_without_vat_formatted = this.formatNumber(new_item.single_purchase_price_with_discount_without_vat, 4);
		}
		
		// calculate and format single price with discount with VAT
		if (formatted_field_name == 'single_purchase_price_with_discount_with_vat_formatted') {
			new_item.single_purchase_price_with_discount_with_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'single_purchase_price_with_discount_with_vat') {
			new_item.single_purchase_price_with_discount_with_vat = 
				purchase_price_without_vat * 
				(1 + purchase_vat / 100) *
				(1 - new_item.invoice_item.purchase_discount / 100);
			new_item.single_purchase_price_with_discount_with_vat_formatted = this.formatNumber(new_item.single_purchase_price_with_discount_with_vat, 4);
		}
		
		// calculate and format price with discount without VAT
		if (formatted_field_name == 'purchase_price_without_vat_formatted') {
			new_item.purchase_price_without_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'purchase_price_without_vat') {
			new_item.purchase_price_without_vat = 
				purchase_price_without_vat * 
				new_item.invoice_item.quantity * 
				(1 - new_item.invoice_item.purchase_discount / 100);
			new_item.purchase_price_without_vat_formatted = this.formatNumber(new_item.purchase_price_without_vat);
		}
		
		// calculate and format price with discount with VAT
		if (formatted_field_name == 'purchase_price_with_vat_formatted') {
			new_item.purchase_price_with_vat_formatted = formatted_field_value;
		}
		else if (field_name != 'purchase_price_with_vat') {
			new_item.purchase_price_with_vat = 
				purchase_price_without_vat * 
				(1 + purchase_vat / 100) *
				new_item.invoice_item.quantity * 
				(1 - new_item.invoice_item.purchase_discount / 100);
			new_item.purchase_price_with_vat_formatted = this.formatNumber(new_item.purchase_price_with_vat);
		}
		
		// format single price with VAT
		if (formatted_field_name == 'price_with_vat_formatted') {
			new_item.price_with_vat_formatted = formatted_field_value;
		}
		else if (!Array.isArray(field_name) || field_name[1] != 'price_with_vat') {
			new_item.price_with_vat_formatted = this.formatNumber(new_item.price_with_vat);
		}
		
		document_items[idx] = new_item;
		
		this.props.setInvoiceItems(document_items);
	}
	
	addItem(item, idx) {
		const item_item = this.props.items[item.id_item];
		
		const document_items = [...this.props.document_items];
		
		let invoice_item = {
			id_delivery_note_item: -1,
			id_delivery_note:      this.state.id_delivery_note,
			id_item:                            item.id_item,
			quantity:                           1,
			price:                              99,
			id_tax_rate:                        null,
			purchase_price_without_vat:         0,
			purchase_id_tax_rate:               -1,
			purchase_discount:                  0,
			item_title:                         null,
			id_item_price_list:                 -1,
			item_measuring_unit_decimal_digit_count: 0,
			ean:                                item_item.ean,
			internal_code:                      item_item.internal_code,
		};
		
		invoice_item = {...invoice_item, ...this.props.findLatestPrice(item_item, true)};
		
		console.log({ type: this.props.type });
		//TODO
		//if (this.props.type == 'acquisition' || this.props.type == 'write-off') {
		//	const id_warehouse = this.props.id_warehouse;
		//	console.log({
		//		id_item: item.id_item,
		//		last_stock_data: id_warehouse === null ? null : this.props.stock_state.last_stock_data[item.id_item + '--' + id_warehouse],
		//		item_price: id_warehouse === null ? null : this.props.stock_state.last_stock_data[item.id_item + '--' + id_warehouse]?.item_price ?? 0,
		//	});
		//	
		//	invoice_item.purchase_price_without_vat = id_warehouse === null ? 0 : this.props.stock_state.last_stock_data[item.id_item + '--' + id_warehouse]?.item_price ?? 0;
		//}
		
		invoice_item.item_measuring_unit_decimal_digit_count =
			this.props.measuring_units[item_item.id_measuring_unit].decimal_digit_count;
		
		document_items[idx] = InvoiceItemViewModel.FromInvoiceItem(
			this.props.items, invoice_item, this.props.tax_rates
		);
		
		this.props.setInvoiceItems(document_items);
		this.addEmptyItemIfNeeded(document_items);
		
		// refresh values
		this.changeInvoiceItemValue(
			document_items[idx],
			idx,
			'refresh',
			null,
			document_items
		);
	}
	
	deleteItem(idx) {
		const document_items = [...this.props.document_items];
		document_items.splice(idx, 1);
		this.props.setInvoiceItems(document_items);
	}
	
	render() {
		let column_idx = 1;
		
		return <div className={
			'items-table ' +
			(this.props.read_only ? 'read-only' : '')
		}>
			{ this.props.read_only ?
				<>
					<div className='items-table-header-col' style={{ gridColumn: column_idx++ }}>
						Urejanje
					</div>
					<div className='items-table-header-col' style={{ gridColumn: column_idx++ }}>
						Šifra in naziv
					</div>
					<div className='items-table-header-col text-center' style={{ gridColumn: column_idx++ }}>
						Količina
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Cena
						<br />
						brez DDV
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Nabavni
						<br />
						DDV (%)
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Cena
						<br />
						z DDV
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Popust (%)
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Cena s pop.
						<br />
						brez DDV
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Cena s pop.
						<br />
						z DDV
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Znesek
						<br />
						brez DDV
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Znesek
						<br />
						z DDV
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						Prodajni
						<br />
						DDV (%)
					</div>
					<div className='items-table-header-col text-right' style={{ gridColumn: column_idx++ }}>
						PC z DDV
					</div>
				</>
				:
				<>
					<div
						className='items-table-header-col'
						style={{ gridColumn: column_idx++ }}>
						Urejanje
					</div>
					<div
						className='items-table-header-col'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('title'); }}>
						Šifra in naziv
						{this.state.main_table_items_sort_field != 'title' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-center'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('quantity'); }}>
						Količina
						{this.state.main_table_items_sort_field != 'quantity' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('single_purchase_price_without_vat'); }}>
						Cena
						<br />
						brez DDV
						{this.state.main_table_items_sort_field != 'single_purchase_price_without_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('single_purchase_price_with_vat'); }}>
						Cena
						<br />
						z DDV
						{this.state.main_table_items_sort_field != 'single_purchase_price_with_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('purchase_discount'); }}>
						Popust (%)
						{this.state.main_table_items_sort_field != 'purchase_discount' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('single_purchase_price_with_discount_without_vat'); }}>
						Cena s pop.
						<br />
						brez DDV
						{this.state.main_table_items_sort_field != 'single_purchase_price_with_discount_without_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('single_purchase_price_with_discount_with_vat'); }}>
						Cena s pop.
						<br />
						z DDV
						{this.state.main_table_items_sort_field != 'single_purchase_price_with_discount_with_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('purchase_price_without_vat'); }}>
						Znesek
						<br />
						brez DDV
						{this.state.main_table_items_sort_field != 'purchase_price_without_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('purchase_price_with_vat'); }}>
						Znesek
						<br />
						z DDV
						{this.state.main_table_items_sort_field != 'purchase_price_with_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('id_tax_rate'); }}>
						Prodajni
						<br />
						DDV (%)
						{this.state.main_table_items_sort_field != 'id_tax_rate' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
					<div
						className='items-table-header-col text-right'
						style={{ gridColumn: column_idx++ }}
						onClick={() => { this.setMainTableItemsSort('price_with_vat'); }}>
						Trenutna zaloga
						{this.state.main_table_items_sort_field != 'price_with_vat' ? null :
							<Icon icon={'caret-' + (this.state.main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
						}
					</div>
				</>
			}
			
			{this.props.document_items.map((item, idx) => {
				const stock_state_item = this.props.all_stock_state.last_stock_data[item.invoice_item.id_item + '--' + (this.props.id_warehouse ?? 'null')];
				console.log({
					item,
					key: item.invoice_item.id_item + '--' + (this.props.id_warehouse ?? 'null'),
					stock_state_item,
				});
				
				return <React.Fragment key={'invoice-item-' + idx}>
					<ItemsListItem
						idx                           ={idx}
						read_only                     ={this.props.read_only}
						item                          ={item}
						items                         ={this.props.items}
						tax_rates                     ={this.props.tax_rates}
						setInvoiceItems               ={this.props.setInvoiceItems}
						addEmptyItemIfNeeded          ={this.addEmptyItemIfNeeded}
						formatNumber                  ={this.formatNumber}
						id_delivery_note ={this.state.id_delivery_note}
						findBestPriceListMatch        ={this.props.findBestPriceListMatch}
						findLatestPrice               ={this.props.findLatestPrice}
						measuring_units               ={this.props.measuring_units}
						changeInvoiceItemValue        ={this.changeInvoiceItemValue}
						addItem                       ={this.addItem}
						deleteItem                    ={this.deleteItem}
						disabled                      ={this.props.disabled}
						stock_state_item              ={stock_state_item} />
				</React.Fragment>;
			})}
		</div>;
	}
}

class AddEditDeliveryNoteDialog extends Component {
	constructor(props) {
		super(props);
		
		this.openSelectCustomerDialog                               = this.openSelectCustomerDialog                              .bind(this);
		this.closeSelectCustomerDialog                              = this.closeSelectCustomerDialog                             .bind(this);
		this.getItemFromState                                       = this.getItemFromState                                      .bind(this);
		this.closeDialog                                            = this.closeDialog                                           .bind(this);
		this.closeDialogWithIgnore                                  = this.closeDialogWithIgnore                                 .bind(this);
		this.confirmCloseWithoutSave                                = this.confirmCloseWithoutSave                               .bind(this);
		this.convertDocumentItems                                   = this.convertDocumentItems                                  .bind(this);
		this.hasChanged                                             = this.hasChanged                                            .bind(this);
		this.isDraft                                                = this.isDraft                                               .bind(this);
		this.toLocalStringWithTime                                  = this.toLocalStringWithTime                                 .bind(this);
		this.findBestPriceListMatch                                 = this.findBestPriceListMatch                                .bind(this);
		this.findLatestPrice                                        = this.findLatestPrice                                       .bind(this);
		this.setInvoiceItems                                        = this.setInvoiceItems                                       .bind(this);
		this.filterItems                                            = this.filterItems                                           .bind(this);
		this.validate                                               = this.validate                                              .bind(this);
		this.calculateSums                                          = this.calculateSums                                         .bind(this);
		this.loadStockInventoryForDate                              = this.loadStockInventoryForDate                             .bind(this);
		
		let draft = props.item === null ? true : props.item.draft;
		if (draft === undefined) draft = true;
		
		let read_only = false;
		//if (!draft) {
		//	read_only = true;
		//}
		
		this.state = {
			id_delivery_note:                       (props.item === null ? uuid.v4() : props.item.id_delivery_note),
			document_number:                        (props.item === null || props.item.document_number === null ? '' : props.item.document_number),
			id_customer:                            (props.item === null ? null : props.item.id_customer || null),
			document_date:                          (props.item === null ? new Date() : props.item.document_date === null ? null : new Date(props.item.document_date)),
			issue_date:                             (props.item === null ? new Date() : props.item.issue_date === null ? null : new Date(props.item.issue_date)),
			order_date:                             (props.item === null ? new Date() : props.item.order_date === null ? null : new Date(props.item.order_date)),
			order_number:                           (props.item === null ? '' : props.item.order_number),
			type:                                   (props.item === null ? null : props.item.type),
			note:                                   (props.item === null ? '' : props.item.note || ''),
			document_items:                         [],
			id_warehouse:                           (props.item === null ? null : props.item.id_warehouse),
			stock_from_id_warehouse:                (props.item === null ? null : props.item.stock_from_id_warehouse),
			delivery_address:                       (props.item === null ? '' : props.item.delivery_address),
			delivery_addresses:                     (props.item === null || props.item.id_customer === null ? [] : props.customers[props.item.id_customer].delivery_addresses),
			select_customer_dialog_open:            false,
			select_customer_dialog_surname:         '',
			close_dialog_save_as_draft:             false,
			close_dialog_confirm_open:              false,
			import_failure_alert_open:              false,
			import_failure_alert_missing_tax_rates: [],
			import_failure_alert_warnings:          [],
			draft,
			read_only,
			filtered_items: this.filterItems(props),
			ignore_prices_for_ids: [],
			customer_delivery_address_picker_dialog_open: false,
		};
		
		this.state.document_items = this.convertDocumentItems(props.item, read_only);
		
		if (this.state.issue_date !== null) {
			const issue_date_iso = moment(this.state.issue_date).format('YYYY-MM-DD');
			this.loadStockInventoryForDate(issue_date_iso);
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.document_items != prevState.document_items) {
			// if an item was deleted, focus new/empty item
			if (this.state.document_items.length < prevState.document_items.length) {
				const service_suggest_inputs = document.querySelectorAll('.service-suggest input');
				if (service_suggest_inputs.length > 0) {
					service_suggest_inputs[service_suggest_inputs.length - 1].focus();
				}
			}
		}
		
		if (this.props.items !== prevProps.items) {
			this.setState({
				filtered_items: this.filterItems(),
			});
		}
		
		if (this.state.issue_date != prevState.issue_date) {
			if (this.state.issue_date !== null) {
				const issue_date_iso = moment(this.state.issue_date).format('YYYY-MM-DD');
				//if (this.props.last_stock_data_by_date[issue_date_iso] === undefined) {
					this.loadStockInventoryForDate(issue_date_iso);
				//}
			}
		}
	}
	
	hasChanged() {
		/*if (this.props.item === null || this.props.item === undefined) {
			return true;
		}
		
		if (this.props.item.id_customer != this.state.id_customer) return true;
		
		let first_payment = null;
		let split_payment_types = [];
		if (this.props.item !== null && this.props.item.payments !== undefined && this.props.item.payments !== null) {
			if (this.props.item.payments.length == 1) {
				first_payment = this.props.item.payments[0];
			}
			split_payment_types = this.props.item.payments;
		}
		
		if (this.state.id_payment_type == -2) {
			if (first_payment !== null) return true;
			
			if (split_payment_types.length != this.state.split_payment_types.length) return true;
			
			let found_diff = false;
			for (let i=0; i<split_payment_types.length; i++) {
				let found = false;
				
				for (let j=0; j<this.state.split_payment_types.length; j++) {
					if (
						this.state.split_payment_types[j].id_payment_type == split_payment_types[i].id_payment_type &&
						this.state.split_payment_types[j].amount          == split_payment_types[i].amount
					) {
						found = true;
						break;
					}
				}
				
				if (!found) {
					found_diff = true;
					break;
				}
			}
			if (found_diff) return true;
		}
		else if (
			first_payment === null ||
			(first_payment !== null && this.state.id_payment_type != first_payment.id_payment_type)
		) {
			return true;
		}*/
		
		return false;
	}
	isDraft() {
		return this.state.draft;
	}
	
	getEmptyInvoiceItem() {
		return {
			id_delivery_note_item: -1,
			id_delivery_note:      -1,
			id_item:                            -1,
			quantity:                            0,
			price:                               0,
			id_tax_rate:                        -1,
			purchase_price_without_vat:          0,
			purchase_id_tax_rate:               -1,
			purchase_discount:                   0,
			item_title:                          null,
			id_item_price_list:                  -1,
			item_measuring_unit_decimal_digit_count: 0,
			ean:                                '',
			internal_code:                      '',
		};
	}
	
	convertDocumentItems(item, read_only) {
		const items = [];
		
		if (item !== null) {
			for (let i=0; i<item.items.length; i++) {
				const existing_item = this.props.items[item.items[i].id_item];
				const item_item = {
					...this.props.items[item.items[i].id_item],
					...item.items[i],
					item_title: existing_item === undefined ? item.items[i].item_title : existing_item.title,
				};
				item_item.id_item_price_list = this.findBestPriceListMatch(item_item);
				
				const invoice_item = InvoiceItemViewModel.FromInvoiceItem(
					this.props.items,
					item_item,
					this.props.tax_rates
				);
				
				// skip if item doesn't exist yet
				if (item.items[i] !== undefined && item.items[i].id_item !== null && this.props.items[item.items[i].id_item] !== undefined) {
					const original_price_data = this.findLatestPrice(this.props.items[item.items[i].id_item], true);
					if (original_price_data.id_item_price_list != -1) {
						invoice_item.original_price_with_vat           = original_price_data.price;
						invoice_item.original_price_with_vat_formatted = this.formatNumber(invoice_item.original_price_with_vat);
					}
					
					const price_list_price_data = this.findLatestPrice(this.props.items[item.items[i].id_item], true);
					if (price_list_price_data.id_item_price_list != -1) {
						invoice_item.price_list_price_with_vat           = price_list_price_data.price;
						invoice_item.price_list_price_with_vat_formatted = this.formatNumber(invoice_item.price_list_price_with_vat);
					}
				}
				
				items.push(invoice_item);
			}
		}
		
		if (!read_only) {
			const empty_invoice_item_view = InvoiceItemViewModel.FromInvoiceItem(this.props.items, this.getEmptyInvoiceItem(), this.props.tax_rates);
			empty_invoice_item_view.invoice_item.quantity = 1;
			
			items.push(empty_invoice_item_view);
		}
		
		return items;
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		}
	}
	
	getMaxDate() {
		const date = new Date();
		date.setFullYear(date.getFullYear() + 10);
		return date;
	}
	
	openSelectCustomerDialog(query, item) {
		item = item || null;
		
		this.setState({
			select_customer_dialog_open: true,
			select_customer_dialog_surname: query,
			select_customer_dialog_item: item,
		});
	}
	closeSelectCustomerDialog(id_customer, new_customer) {
		const state = {
			select_customer_dialog_open: false,
		};
		if (id_customer !== undefined) {
			state.id_customer = id_customer;
		}
		
		this.setState(state);
	}
	
	getItemFromState(save_as_draft) {
		const items = this.state.document_items
			.filter(x => /*x.invoice_item.id_item !== null &&*/ x.invoice_item.id_item != -1)
			.map(x => {
				const id_delivery_note_item = uuid.v4();
				
				return {
					changed_price: x.original_price_with_vat != x.price_with_vat,
					is_new: x.invoice_item.id_delivery_note_item == -1,
					item: {
						id_delivery_note_item,
						id_delivery_note:      this.state.id_delivery_note,
						id_item:                            x.invoice_item.id_item,
						quantity:                           x.invoice_item.quantity,
						price:                              x.price_with_vat,
						id_tax_rate:                        x.invoice_item.id_tax_rate,
						purchase_id_tax_rate:               x.invoice_item.purchase_id_tax_rate,
						purchase_price_without_vat:         x.single_purchase_price_without_vat,
						purchase_discount:                  x.invoice_item.purchase_discount,
						title:                              x.invoice_item.item_title,
						ean:                                x.invoice_item.ean,
						internal_code:                      x.invoice_item.internal_code,
						show_description_on_documents:      x.invoice_item.show_description_on_documents ?? false,
						allowed_discount:                   0,
						allow_changing_price:               true,
						description:                        '',
						id_item_price_list:                 x.invoice_item.id_item_price_list,
					},
				};
			});
		
		const items_with_changed_prices = items.filter(x => x.changed_price);
		const items_with_changed_prices_keyed = {};
		for (let i=0; i<items_with_changed_prices.length; i++) {
			items_with_changed_prices_keyed[
				items_with_changed_prices[i].item.id_delivery_note_item
			] = items_with_changed_prices[i].item;
		}
		
		const ignore_existing_unchanged_ids = items
			.filter(x => !x.changed_price)
			.map   (x => x.item.id_delivery_note_item);
		
		return {
			items_with_changed_prices: items_with_changed_prices_keyed,
			ignore_existing_unchanged_ids,
			item: {
				id_delivery_note: this.state.id_delivery_note,
				document_number:               this.state.document_number,
				id_user:                       this.props.user.id_user,
				id_customer:          this.state.id_customer,
				document_date:                 this.state.document_date === null ? null : moment(this.state.document_date).format('YYYY-MM-DD'),
				id_warehouse:                  this.state.id_warehouse,
				type:                          this.state.type,
				draft:                         save_as_draft,
				issue_date:                     this.state.issue_date === null ? null : moment(this.state.issue_date).format('YYYY-MM-DD'),
				order_date:            this.state.order_date === null ? null : moment(this.state.order_date).format('YYYY-MM-DD'),
				order_number:          this.state.order_number,
				note:                          this.state.note,
				stock_from_id_warehouse:       this.state.stock_from_id_warehouse,
				delivery_address:              this.state.delivery_address,
				items: items.map(x => x.item),
			},
		};
	}
	
	recalculateTable(document_items) {
		document_items = document_items || this.state.document_items;
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	closeDialog(save, save_as_draft) {
		if (!save) {
			this.props.closeDialog();
			return;
		}
		
		const validation_messages = this.validate();
		if (validation_messages.length > 0) {
			AppToaster.show({
				message: <div>
					Napake na obrazcu:
					<br />
					{validation_messages.map((title, idx) => <div key={idx}>{'• ' + title}</div>)}
				</div>,
				intent: 'danger',
				icon:   'issue',
			});
			return;
		}
		
		const { items_with_changed_prices, ignore_existing_unchanged_ids, item } = this.getItemFromState(save_as_draft);
		
		this.closeDialogWithIgnore(item, ignore_existing_unchanged_ids);
	}
	closeDialogWithIgnore(item, ignore_prices_for_id_delivery_note_items) {
		this.props.closeDialog(
			item,
			ignore_prices_for_id_delivery_note_items,
			this.state.delivery_addresses,
		);
	}
	confirmCloseWithoutSave() {
		this.setState({
			close_dialog_save_as_draft: false,
			close_dialog_confirm_open: true,
		});
	}
	
	toLocalStringWithTime(date) {
		if (date === null) return '';
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear() + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' +
			date.getMinutes().toString().padStart(2, '0') + ':' +
			date.getSeconds().toString().padStart(2, '0');
	}
	
	findBestPriceListMatch(item) {
		if (item === undefined || item === null || this.state.document_date === null || this.state.document_date === undefined) return null;
		
		return PriceHelper.FindBestPriceListMatch(this.state.document_date, item.price_lists);
	}
	findLatestPrice(item, skip_delivery_notes) {
		skip_delivery_notes = skip_delivery_notes || false;
		
		let delivery_notes = skip_delivery_notes ? [] : Object.values(this.props.delivery_notes);
		
		// limit to current document
		//if (this.props.item !== null) {
		//	delivery_notes = delivery_notes.filter(x => x.id_delivery_note == this.state.id_delivery_note)
		//}
		
		delivery_notes = delivery_notes
			.map(x => {
				let found_item = null;
				for (let i=0; i<x.items.length; i++) {
					if (x.items[i].id_item == item.id_item) {
						found_item = x.items[i];
					}
				}
				
				return { document_date: x.document_date, delivery_note_item: found_item };
			})
			.filter(x => x.delivery_note_item !== null)
			.sort((a, b) => {
				if      (a.document_date > b.document_date) return -1;
				else if (a.document_date < b.document_date) return  1;
				return 0;
			});
		if (delivery_notes.length > 0) {
			const delivery_note_item = delivery_notes[0].delivery_note_item;
			const id_item_price_list              = this.findBestPriceListMatch(this.props.items[delivery_note_item.id_item]);
			return {
				id_tax_rate:                delivery_note_item.id_tax_rate,
				price:                      delivery_note_item.price, // figure out from
				purchase_id_tax_rate:       stock_acquisition_document_item.purchase_id_tax_rate,
				purchase_price_without_vat: delivery_note_item.purchase_price_without_vat,
				id_item_price_list:         id_item_price_list,
			};
		}
		
		const id_item_price_list = this.findBestPriceListMatch(item);
		if (id_item_price_list !== null) {
			const retval = {
				id_tax_rate:                item.price_lists[id_item_price_list].id_tax_rate,
				price:                      item.price_lists[id_item_price_list].price,
				purchase_id_tax_rate:       0,
				purchase_price_without_vat: 0,
				id_item_price_list,
			};
			retval.purchase_id_tax_rate = retval.id_tax_rate;
			retval.purchase_price_without_vat = retval.price / (1 + this.props.tax_rates[retval.id_tax_rate].value / 100);
			
			return retval;
		}
		
		return {
			id_tax_rate:                 null,
			price:                       this.props.items[item.id_item].price,
			purchase_id_tax_rate:       -1,
			purchase_price_without_vat:  0,
			id_item_price_list:         -1,
		};
	}
	
	setInvoiceItems(document_items) {
		this.setState({ document_items });
	}
	
	filterItems(props) {
		props = props || this.props;
		
		const items = {};
		for (let id_item in props.items) {
			const item = props.items[id_item];
			if (item.type == 'item') {
				items[id_item] = item;
			}
		}
		
		return items;
	}
	
	validate() {
		const messages = [];
		
		if (this.state.id_customer === null) {
			messages.push('Dobavitelj ni izbran');
		}
		
		if (this.state.id_warehouse === null || this.state.id_warehouse == '-1') {
			messages.push('Skladišče ni izbrano');
		}
		
		if (this.state.type === null || this.state.type == '-1') {
			messages.push('Tip prevzema ni izbran');
		}
		
		if (this.state.document_date === null) {
			messages.push('Datum prevzema ni izbran');
		}
		
		if (this.state.issue_date === null) {
			messages.push('Datum izdaje ni izbran');
		}
		
		if (this.state.document_items.length <= 1) {
			messages.push('Dokument ne sme biti prazen');
		}
		
		const internal_code_counts = {};
		const ean_counts           = {};
		
		const quantity_must_be_positive = this.state.type == 'internal-transfer';
		let   negative_quantities_exist = false;
		
		for (let i=0; i<this.state.document_items.length; i++) {
			const item = this.state.document_items[i];
			if (item.invoice_item.id_item == -1) continue;
			
			let title = item.invoice_item.item_title;
			if (title === null && item.invoice_item.id_item !== null && this.props.items[item.invoice_item.id_item] !== undefined) {
				title = this.props.items[item.invoice_item.id_item].title;
			}
			
			if (item.invoice_item.internal_code !== null && item.invoice_item.internal_code.length > 0) {
				if (internal_code_counts[item.invoice_item.internal_code] === undefined) {
					internal_code_counts[item.invoice_item.internal_code] = { id_item: item.invoice_item.id_item, title, count: 0 };
				}
				internal_code_counts[item.invoice_item.internal_code].count++;
			}
			if (item.invoice_item.ean !== null && item.invoice_item.ean.length > 0) {
				if (ean_counts[item.invoice_item.ean] === undefined) {
					ean_counts[item.invoice_item.ean] = { id_item: item.invoice_item.id_item, title, count: 0 };
				}
				ean_counts[item.invoice_item.ean].count++;
			}
			
			if ([
				item.price_with_vat,
				item.purchase_price_with_vat,
				item.purchase_price_without_vat,
				item.single_purchase_price_with_discount_with_vat,
				item.single_purchase_price_with_discount_without_vat,
				item.single_purchase_price_with_vat,
				item.single_purchase_price_without_vat,
				item.invoice_item.price,
				item.invoice_item.purchase_discount,
				item.invoice_item.purchase_price_without_vat,
				item.invoice_item.quantity,
			].some(x => isNaN(x) || x === '')) {
				messages.push('Artikel ' + (title === null ? 'neznano' : title) + ' ima vsaj eno neveljavno vrednost');
			}
			if (item.invoice_item.quantity == 0) {
				messages.push('Artikel ' + (title === null ? 'neznano' : title) + ' ima količino 0');
			}
			if (item.price_with_vat <= 0) {
				messages.push('Artikel ' + (title === null ? 'neznano' : title) + ' ima ceno 0 ali manj');
			}
			if (item.single_purchase_price_without_vat_formatted <= 0) {
				messages.push('Artikel ' + (title === null ? 'neznano' : title) + ' ima nabavno ceno 0 ali manj');
			}
			if (item.invoice_item.id_tax_rate == -1) {
				messages.push('Artikel ' + (title === null ? 'neznano' : title) + ' nima nastavljene davčne stopnje');
			}
			
			if (quantity_must_be_positive && item.invoice_item.quantity < 0) {
				negative_quantities_exist = true;
			}
		}
		
		for (let internal_code in internal_code_counts) {
			if (internal_code_counts[internal_code].count <= 1) continue;
			
			const title = internal_code_counts[internal_code].title;
			
			messages.push('Artikel ' + (title === null ? 'neznano' : title) + ' se ponovi več kot enkrat');
		}
		for (let ean in ean_counts) {
			if (ean_counts[ean].count <= 1) continue;
			
			const title = ean_counts[ean].title;
			
			const message = 'Artikel ' + (title === null ? 'neznano' : title) + ' se ponovi več kot enkrat';
			if (messages.indexOf(message) == -1) {
				messages.push(message);
			}
		}
		
		if (negative_quantities_exist) {
			messages.push('Vse količine morajo biti pozitivne');
		}
		
		if (this.state.stock_from_id_warehouse !== null && this.state.stock_from_id_warehouse === this.state.id_warehouse) {
			messages.push('Zalogo je lahko prenešena samo v drugo skladišče');
		}
		
		return messages;
	}
	
	calculateSums() {
		const sums = {
			purchase_price_without_vat: 0,
			purchase_price_with_vat:    0,
			quantity:                   0,
			count:                      0,
		};
		
		for (let i=0; i<this.state.document_items.length; i++) {
			const item = this.state.document_items[i];
			if (item.invoice_item.id_item === -1) continue
			
			sums.purchase_price_without_vat += parseFloat(item.purchase_price_without_vat);
			sums.purchase_price_with_vat    += parseFloat(item.purchase_price_with_vat);
			sums.quantity                   += parseFloat(item.invoice_item.quantity);
			sums.count                      += 1;
		}
		
		return sums;
	}
	
	loadStockInventoryForDate(date_iso) {
		loadLastStockDataByDate(
			this.props.api_url,
			this.props.dispatch,
			this.props.token,
			date_iso
		);
	}
	
	render() {
		const customer = (this.state.id_customer === null ? null : (this.props.customers[this.state.id_customer]) || null);
		
		const sums = this.calculateSums();
		
		const disabled =
			this.state.id_warehouse === null ||
			this.state.id_warehouse == '-1'  ||
			this.state.type         === null ||
			this.state.type         == '-1'  ||
			this.state.issue_date    === null ||
			this.props.last_stock_data_by_date[moment(this.state.issue_date).format('YYYY-MM-DD')] === undefined;
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			className='add-edit-delivery-note-dialog'>
			<div className='flex flex-col flex-grow'>
				{!this.state.select_customer_dialog_open ? null :
					<SelectCustomerDialog
						closeSelectCustomerDialog={this.closeSelectCustomerDialog}
						surname={this.state.select_customer_dialog_surname}
						item={null} />
				}
				
				{!this.state.close_dialog_confirm_open ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Zapri'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='help'
						intent={Intent.PRIMARY}
						isOpen={true}
						onConfirm={() => {
							this.closeDialog(
								false,
								this.state.close_dialog_save_as_draft
							);
							this.setState({
								close_dialog_save_as_draft: false,
								close_dialog_confirm_open:  false,
							});
						}}
						onCancel={() => {
							this.setState({
								close_dialog_save_as_draft: false,
								close_dialog_confirm_open:  false,
							});
						}}>
						Res želite zapreti brez shranjevanja?
					</Alert>
				}
				
				<Alert
					confirmButtonText='V redu'
					icon='warning-sign'
					intent={Intent.WARNING}
					isOpen={this.state.import_failure_alert_open && this.state.import_failure_alert_missing_tax_rates.length > 0}
					onConfirm={() => { this.setState({ import_failure_alert_open: false, import_failure_alert_missing_tax_rates: [] }); }}>
					<div>
						<h3 className='bp5-heading'>Uvoz ni uspel</h3>
						<div>Manjkajo naslednje stopnje davka: {this.state.import_failure_alert_missing_tax_rates.map(x => this.formatNumber(x) + '%').join(', ')}</div>
					</div>
				</Alert>
				
				<Alert
					confirmButtonText='V redu'
					icon='warning-sign'
					intent={Intent.WARNING}
					isOpen={this.state.import_failure_alert_open && Object.keys(this.state.import_failure_alert_warnings).length > 0}
					onConfirm={() => { this.setState({ import_failure_alert_open: false, import_failure_alert_warnings: [] }); }}>
					<div>
						<h3 className='bp5-heading'>Uvoz ni uspel</h3>
						<div className='text-selectable'>{Object.keys(this.state.import_failure_alert_warnings).map(warning =>
							<div key={warning} className='text-selectable'>
								<div className='text-selectable font-semibold mt-1'>{warning + ':'}</div>
								{this.state.import_failure_alert_warnings[warning].map((x, idx) =>
									<div key={'warning-' + warning + '-' + idx} className='text-selectable'>
										{x}
									</div>
								)}
							</div>
						)}</div>
					</div>
				</Alert>
				
				{!this.state.select_customer_dialog_open ? null :
					<SelectCustomerDialog
						closeSelectCustomerDialog={this.closeSelectCustomerDialog}
						surname={this.state.select_customer_dialog_surname}
						item={this.state.select_customer_dialog_item} />
				}
				
				{customer === null || !this.state.customer_delivery_address_picker_dialog_open ? null :
					<CustomerDeliveryAddressPickerDialog
						customer={customer}
						closeDialog={(address, delivery_addresses) => {
							if (address !== undefined) {
								this.setState({ delivery_address: address });
							}
							this.setState({ customer_delivery_address_picker_dialog_open: false, delivery_addresses });
						}} />
				}
				
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>{ 'Vnos dobavnice' }</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button intent='primary' icon='floppy-disk' onClick={() => this.closeDialog(true, true)} className='w-48' disabled={true || !this.isDraft()}>
							Shrani kot osnutek
						</Button>
						<Button intent='success' icon='tick' onClick={() => this.closeDialog(true, false)} className='w-48 ml-2' disabled={/*!this.isDraft() && !this.hasChanged()*/false}>
							Zapri in zaključi
						</Button>
						<Button intent='danger' icon='cross' onClick={() => this.confirmCloseWithoutSave()} className='w-48 ml-10'>
							Zapri brez shranjevanja
						</Button>
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-col'>
						<div className='invoice-header'>
							<div className='document-number'>
								<h3 className='bp5-heading'>
									<span className='font-thin'>{ 'Dobavnica št.: ' }</span>
									{this.state.draft ? 'osnutek' : this.state.document_number}
								</h3>
							</div>
							<div className='customer'>
								<CustomerSelect
									itemSelected={item => {
										this.setState({
											id_customer: item.id_customer,
											delivery_address: (item.delivery_addresses.length == 0 ? '' : item.delivery_addresses[0]),
											delivery_addresses: item.delivery_addresses,
										});
									}}
									addPersonClick={query => { this.openSelectCustomerDialog(query); }}
									fieldTitle='Stranka'
									formGroupCls='mb-0'
									focus={true} />
								
								<div className='customer-info' style={{ position: 'relative' }}>
									{
										customer === null ? null :
										<>
											<div className='customer-info-inner'>
												<div>
													{ customer.type == 'natural' ? customer.surname + ' ' + customer.name : customer.company_name }
												</div>
												<div>
													{(() => {
														const arr = [];
														if (customer.address.length > 0) {
															arr.push(<div key='address'>{customer.address}</div>);
														}
														if (customer.post_office.length > 0) {
															arr.push(<div key='post-office'>{(customer.post_code + ' ' + customer.post_office).trim()}</div>);
														}
														if (customer.id_country !== null) {
															arr.push(<div key='country'>{this.props.countries[customer.id_country].iso_3166_1_a2}</div>);
														}
														
														return arr;
													})()}
												</div>
												<div className='pt-2'>
													{ 
														customer.vat_registered ?
															('ID za DDV: ' + customer.vat_prefix + customer.tax_number) :
															('Davčna št.: ' + customer.tax_number)
													}
												</div>
											</div>
											<div className='opacity-50 text-right pr-2' style={{ position: 'absolute', right: 0, bottom: 0 }}>
												{ customer.internal_code }
											</div>
											<div style={{ position: 'absolute', right: 0, top: 11 }}>
												<Button
													icon='edit'
													minimal={true}
													onClick={() => {
														this.openSelectCustomerDialog('', customer);
													}} />
											</div>
											<div style={{ position: 'absolute', right: 0, top: 32 }}>
												<Button
													icon='cross'
													minimal={true}
													onClick={() => {
														this.setState({ id_customer: null });
													}} />
											</div>
										</>
									}
								</div>
							</div>
							<div className='document-date'>
								<FormGroup
									labelFor='add-edit-stock-acq-document--document-date'>
									<TextWithTooltip
										label='Datum dobavnice'
										tooltip='Datum dobavnice' />
									<DateInput3
										id='add-edit-stock-acq-document--document-date'
										dateFnsLocaleLoader={loadDateFnsLocale}
										dateFnsFormat={'dd. MM. yyyy'}
										locale='sl'
										inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
										selectAllOnFocus={true}
										minDate={new Date('1900-01-01')}
										maxDate={moment().add('10', 'years').toDate()}
										disabled={this.state.read_only}
										onChange={(selectedDate, isUserChange) => {
											if (isUserChange) this.setState({ document_date: selectedDate === null ? null : new Date(selectedDate) });
										}}
										value={this.state.document_date === null ? null : formatISO(this.state.document_date)} />
								</FormGroup>
							</div>
							<div className='issue-date'>
								<FormGroup
									labelFor='add-edit-stock-acq-document--issue-date'>
									<TextWithTooltip
										label='Datum izdaje'
										tooltip='Datum izdaje' />
									<DateInput3
										id='add-edit-stock-acq-document--issue-date'
										dateFnsLocaleLoader={loadDateFnsLocale}
										dateFnsFormat={'dd. MM. yyyy'}
										locale='sl'
										inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
										selectAllOnFocus={true}
										minDate={new Date('1900-01-01')}
										maxDate={moment().add('10', 'years').toDate()}
										onChange={(selectedDate, isUserChange) => {
											if (isUserChange) {
												this.setState({
													issue_date: selectedDate === null ? null : new Date(selectedDate),
												});
											}
										}}
										value={this.state.issue_date === null ? null : formatISO(this.state.issue_date)} />
								</FormGroup>
							</div>
							<div className='order-date'>
								<FormGroup
									labelFor='add-edit-stock-acq-document--order-date'>
									<TextWithTooltip
										label='Datum naročila'
										tooltip='Datum naročila' />
									<DateInput3
										id='add-edit-stock-acq-document--order-date'
										dateFnsLocaleLoader={loadDateFnsLocale}
										dateFnsFormat={'dd. MM. yyyy'}
										locale='sl'
										inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
										selectAllOnFocus={true}
										minDate={new Date('1900-01-01')}
										maxDate={moment().add('10', 'years').toDate()}
										disabled={this.state.read_only}
										onChange={(selectedDate, isUserChange) => {
											if (isUserChange) this.setState({ order_date: selectedDate === null ? null : new Date(selectedDate) });
										}}
										value={this.state.order_date === null ? null : formatISO(this.state.order_date)} />
								</FormGroup>
							</div>
							<div className='order-number'>
								<FormGroup
									labelFor='add-edit-stock-acq-document--order-number'>
									<TextWithTooltip
										label='Številka naročila'
										tooltip='Številka naročila' />
									<InputGroup
										id='add-edit-stock-acq-document--order-number'
										value={this.state.order_number}
										onChange={event => {
											this.setState({ order_number: event.target.value });
										}} />
								</FormGroup>
							</div>
							<div className='type'>
								<FormGroup
									labelFor='add-edit-stock-acq-document--type'>
									<TextWithTooltip
										label='Vrsta dobavnice'
										tooltip='Vrsta dobavnice' />
									<HTMLSelect
										id='add-edit-stock-acq-document--type'
										fill={true}
										options={[
											{ label: '-- izberi --', value: -1 },
											...Object.keys(Types)
												.map(x => { return { label: Types[x], value: x }; }),
										]}
										value={this.state.type || ''}
										onChange={(e) => {
											this.setState({
												type: e.currentTarget.value,
											});
										}} />
								</FormGroup>
							</div>
							<div className='warehouse'>
								<FormGroup
									labelFor='add-edit-stock-acq-document--warehouse'>
									<TextWithTooltip
										label='Skladišče'
										tooltip='Skladišče' />
									<HTMLSelect
										id='add-edit-stock-acq-document--warehouse'
										fill={true}
										options={[
											{ label: '-- izberi --', value: -1 },
											...Object.values(this.props.warehouses)
												.map(x => { return { label: x.title, value: x.id_warehouse }; })
										]}
										value={this.state.id_warehouse || ''}
										onChange={(e) => {
											this.setState({
												id_warehouse: e.currentTarget.value,
											});
										}} />
								</FormGroup>
							</div>
							<div className='documents flex flex-row'>
								<Tabs className='flex flex-col flex-1' vertical={false}>
									<Tab id='comment' title='Opomba' panelClassName='flex-grow' panel={
										<TextArea
											className='table-full-h'
											fill={true}
											onChange={(e) => {
												this.setState({
													note: e.target.value,
												});
											}}
											value={this.state.note} />
									} />
									
									<Tab id='documents' title='Dokumenti' panelClassName='flex-grow h-full' panel={
										this.props.item === null || this.props.item.id_delivery_note === null ? null :
											<Documents id_document={this.props.item.id_delivery_note} deep={true} />
									} />
									
									<Tab id='delivery-address' title='Naslov za dostavo' panelClassName='flex flex-col flex-grow h-full' panel={
										this.state.id_customer === null ? null :
											<div className='flex flex-col flex-1'>
												{customer.delivery_addresses.length == 0 ? null :
													<Button className='w-48 mt-1 mb-1' onClick={() => {
														this.setState({ customer_delivery_address_picker_dialog_open: true });
													}}>
														Izberi naslov
													</Button>
												}
												<TextArea
													className='table-full-h'
													fill={true}
													onChange={(e) => {
														this.setState({
															delivery_address: e.target.value,
														});
													}}
													value={this.state.delivery_address} />
											</div>
									} />
								</Tabs>
							</div>
						</div>
						
						<div className='items-list-wrapper flex-1'>
							<div className='items-list-scroll-container'>
								<div className='items-list'>
									<ItemsList
										read_only={this.state.read_only}
										document_items={this.state.document_items}
										items={this.state.filtered_items}
										tax_rates={this.props.tax_rates}
										invoice_date={this.state.invoice_date}
										measuring_units={this.props.measuring_units}
										stock_state={disabled ? null : this.props.last_stock_data_by_date[moment(this.state.issue_date).format('YYYY-MM-DD')]}
										setInvoiceItems={this.setInvoiceItems}
										document_date={this.state.document_date}
										findBestPriceListMatch={this.findBestPriceListMatch}
										findLatestPrice={this.findLatestPrice}
										id_warehouse={this.state.id_warehouse}
										type={this.state.type}
										disabled={disabled}
										all_stock_state={this.props.stock_state} />
								</div>
							</div>
						</div>
						
						<div className='items-summary font-semibold text-base'>
							<div className='col-1 font-normal'>Število vrstic:</div>
							<div className='col-2'></div>
							<div className='col-3 text-right font-normal'>{sums.count}</div>
							<div className='col-4'></div>
							<div className='col-5'>Rabat:</div>
							<div className='col-6 text-right'>
								{this.formatNumber(
									0
								)}
							</div>
							<div className='col-7'></div>
							<div className='col-8'>Znesek brez DDV:</div>
							<div className='col-9 text-right'>
								{this.formatNumber(
									sums.purchase_price_without_vat
								)}
							</div>
							
							<div className='col-1 font-normal'>Skupna količina:</div>
							<div className='col-2'></div>
							<div className='col-3 text-right font-normal'>{sums.quantity}</div>
							<div className='col-4'></div>
							<div className='col-5'>Odvisni stroški:</div>
							<div className='col-6 text-right'>
								{this.formatNumber(
									0
								)}
							</div>
							<div className='col-7'></div>
							<div className='col-8'>Znesek DDV:</div>
							<div className='col-9 text-right'>
								{this.formatNumber(
									sums.purchase_price_with_vat - sums.purchase_price_without_vat
								)}
							</div>
							
							<div className='col-1'></div>
							<div className='col-2'></div>
							<div className='col-3'></div>
							<div className='col-4'></div>
							<div className='col-5'></div>
							<div className='col-6'></div>
							<div className='col-7'></div>
							<div className='col-8'>Znesek z DDV:</div>
							<div className='col-9 text-right'>
								{this.formatNumber(
									sums.purchase_price_with_vat
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditDeliveryNoteDialog.propTypes = {
	closeDialog: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		customers:                                              state.CodeTablesSlice.customers,
		payment_types:                                          state.CodeTablesSlice.payment_types,
		items:                                                  state.CodeTablesSlice.items,
		countries:                                              state.CodeTablesSlice.countries,
		tax_rates:                                              state.CodeTablesSlice.tax_rates,
		measuring_units:                                        state.CodeTablesSlice.measuring_units,
		warehouses:                                             state.CodeTablesSlice.warehouses,
		api_url:                                                state.CodeTablesSlice.api_url,
		token:                                                  state.UserSlice.token,
		user:                                                   state.UserSlice.user,
		settings:                                               state.SettingsSlice,
		document_types:                                         state.DocumentSlice.document_types,
		current_id_cash_register:                               state.SettingsSlice.current_id_cash_register,
		invoices:                                               state.BusinessSlice.invoices,
		advance_invoice_consumptions:                           state.BusinessSlice.advance_invoice_consumptions,
		id_advance_invoice_consumption_by_id_consumer_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
		delivery_notes:                            state.WarehouseSlice.delivery_notes,
		last_stock_data_by_date:                                state.WarehouseSlice.last_stock_data_by_date,
		stock_state:             state.WarehouseSlice.stock_state,
	};
}

export default connect(mapStateToProps)(AddEditDeliveryNoteDialog);
