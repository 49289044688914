/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Tags: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="tags" ref={ref}  {...props}>
            <path
                d={isLarge ? "M60 340C60 362.0914 77.9086 380 100 380H191.7538A40.001 40.001 0 0 0 220.038 368.2842L368.23 220.0918C383.852 204.4708 383.852 179.144 368.23 163.524L276.476 71.77C260.856 56.148 235.53 56.148 219.908 71.77L71.7158 219.962A39.9994 39.9994 0 0 0 60 248.2462V340zM129.7728 340.1298C113.258 340.1298 99.8702 326.742 99.8702 310.2272S113.258 280.3246000000001 129.7728 280.3246000000001S159.6754 293.7124 159.6754 310.2272S146.2876 340.1298 129.7728 340.1298zM40 306.2284L25.3676 281.0578C20.0548 271.919 18.6152 261.0584 21.3652 250.8654L75.693 49.5C81.4198 28.2740000000001 103.3878 15.6780000000001 124.7602 21.366L220.456 46.832C215.168 49.588 210.206 53.188 205.766 57.628L57.5736 205.8198A60 60 0 0 0 40 248.2462V306.2284z" : "M80 320H171.685A40 40 0 0 0 199.9692 308.2842L308.2220000000001 200.0308C323.844 184.4098 323.844 159.0832 308.2220000000001 143.4622L216.5380000000001 51.778C200.916 36.156 175.5902000000001 36.156 159.9692 51.778L51.7158 160.0308A39.9994 39.9994 0 0 0 40 188.315V280C40 302.0914 57.9086 320 80 320zM79.8516 250.1484C79.8516 233.5798 93.2832 220.1484 109.8516 220.1484C126.4202 220.1484 139.8516 233.5798 139.8516 250.1484C139.8516 266.7168 126.4202 280.1484 109.8516 280.1484C93.2832 280.1484 79.8516 266.7168 79.8516 250.1484zM5.2524 216.4818L20 243.6222V188.3148C20 172.402 26.3214 157.1406 37.5736 145.8884L145.8272 37.634C152.0692 31.392 159.3444 26.814 167.1022 23.898L88.1842 1.43C67.2706 -4.5240000000001 45.774 8.662 40.1702 30.884L1.336 184.874C-1.3552 195.545 0.0536 206.9146 5.2524 216.4818z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Tags.defaultProps = {
    size: IconSize.STANDARD,
};
Tags.displayName = `Blueprint5.Icon.Tags`;
export default Tags;
