/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const BlockPromote: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="block-promote" ref={ref}  {...props}>
            <path
                d={isLarge ? "M360 220H340V320C340 331 331 340 320 340H80C69 340 60 331 60 320V220H40C29 220 20 211 20 200S29 180 40 180H60V80C60 69 69 60 80 60H320C331 60 340 69 340 80V180H360C371 180 380 189 380 200S371 220 360 220zM137 133C135.2 131 132.6 130 130 130C127.4 130 124.8 131 123 133C119 136.8 119 143.2 123 147L175.8 200L123 253C119 256.8 119 263.2 123 267C126.8 271 133.2 271 137 267L197 207C201 203.2000000000001 201 196.8000000000001 197 193.0000000000001L137 133.0000000000001zM277 193L217 133C215.2 131 212.6 130 210 130C207.4 130 204.8 131 203 133C199 136.8 199 143.2 203 147L255.8 200L203 253C199 256.8 199 263.2 203 267C206.8 271 213.2 271 217 267L277 207C281 203.2000000000001 281 196.8000000000001 277 193.0000000000001z" : "M60 280H260C271.046 280 280 271.0456 280 260V180H300C311.046 180 320 171.0456 320 160C320 148.9542 311.046 140 300 140H280V60C280 48.954 271.046 40 260 40H60C48.9544 40 40 48.954 40 60V140H20C8.9542 140 0 148.9542 0 160C0 171.0456 8.9542 180 20 180H40V260C40 271.0456 48.9544 280 60 280zM177.071 217.071C173.1658 220.9764 166.8342 220.9764 162.929 217.071C159.0236 213.1658 159.0236 206.8342 162.929 202.929L205.858 160L162.929 117.072C159.0236 113.166 159.0236 106.834 162.929 102.928C166.8342 99.024 173.1658 99.024 177.071 102.928L227.072 152.929C230.976 156.8342 230.976 163.1658 227.072 167.071L177.071 217.071zM92.929 217.071C89.0236 213.1658 89.0238 206.8342 92.929 202.929L135.858 160.0002L92.929 117.072C89.0236 113.166 89.0236 106.834 92.929 102.928C96.8342 99.024 103.1658 99.024 107.071 102.928L157.071 152.929A9.9582 9.9582 0 0 1 159.9542 159.0418A10.062 10.062 0 0 1 159.9324 161.1652A9.958 9.958 0 0 1 157.071 167.0714L107.071 217.071C103.1658 220.9764 96.8342 220.9762 92.929 217.071z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
BlockPromote.defaultProps = {
    size: IconSize.STANDARD,
};
BlockPromote.displayName = `Blueprint5.Icon.BlockPromote`;
export default BlockPromote;
