/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const CubeEdit: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="cube-edit" ref={ref}  {...props}>
            <path
                d={isLarge ? "M276.18 356L212.76 390.4A27 27 0 0 1 187.24 390.4L39.06 310L159.34 239.16zM144.74 224.54L22.72 296.4A24 24 0 0 1 20 285.48V118.48C20 109.72 24.94 101.62 32.96 97.28L187.24 13.5599999999999Q188.6 12.8199999999999 190 12.2599999999999V123.1199999999999L126.64 101.0999999999999A20 20 0 0 0 101.16 126.4999999999999L128.06 204.3599999999999A20 20 0 0 0 133.14 212.9599999999999zM210 130.74V12.26Q211.4 12.8199999999999 212.76 13.56L367.04 97.28C375.04 101.62 380 109.72 380 118.48V285.48C380 289.3400000000001 379.04 293.0800000000001 377.2800000000001 296.4000000000001L374.2000000000001 294.6L362.6 283A20 20 0 0 0 361.24 281.4600000000001L212.9400000000001 133.16A20 20 0 0 0 210 130.74M161.34 212.9L212.84 161.4L347.02 295.58L295.5 347.08zM120 119.96L198.7 147.3L147.24 198.78zM364.42 400.08C354.62 400.08 345.04 396.86 338.62 390.26L309.62 361.18L361.0200000000001 309.7L390 338.8C396.4 345.4 399.98 354.64 399.98 364.44C399.98 384.04 383.98 400.1 364.4 400.1" : "M174.8 135.94L136 174.74L236.44 275.16L275.24 236.36zM100 99.84L121.86 160.5L160.7 121.68zM273.14 312C278.16 316.86 285.08 320 292.64 320C307.9 320 320.16 307.6 320 292.6600000000001C320 284.96 317 278.2 311.98 273.18L289.3400000000001 250.54L250.5 289.3400000000001zM169.92 314.32L219.2 286.2L126.02 193.02L36.12 249.22L150.08 314.32A20 20 0 0 0 169.92 314.32M21.12 235L111.52 178.52L107.66 174.66A20 20 0 0 1 102.54 165.86L81.18 106.6A20 20 0 0 1 106.78 81L150 96.54V5.7L30.08 74.22A20 20 0 0 0 20 91.6V228.4A20 20 0 0 0 21.12 235M170 5.72V103.96A20 20 0 0 1 176.28 109.12L289.38 222.22L290.3800000000001 223.3L299.5800000000001 232.5Q300.0000000000001 230.48 300.0000000000001 228.4V91.6A20 20 0 0 0 289.9200000000001 74.24z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
CubeEdit.defaultProps = {
    size: IconSize.STANDARD,
};
CubeEdit.displayName = `Blueprint5.Icon.CubeEdit`;
export default CubeEdit;
