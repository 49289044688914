/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Package: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="package" ref={ref}  {...props}>
            <path
                d={isLarge ? "M236.978 393.8728L377.8880000000001 323.4172C391.44 316.6416000000001 400 302.791 400 287.6402V113.542C400 97.186 390.042 82.476 374.856 76.402L195.7668 4.766A40.0116 40.0116 0 0 0 163.0226 6.128L22.1114 76.584C8.56 83.36 0 97.21 0 112.362V286.4602C0 302.8164 9.958 317.5246 25.1444 323.5992L204.234 395.2348A40 40 0 0 0 236.978 393.8728zM200 206.4606V49.542L360 113.542V270.4606L300 246.4606V143.2820000000001L260 127.422V230.4606L200 206.4606z" : "M196 317L298 265C312 259 320 245 320 229V93C320 77 310 61 294 55L156 3C146 -1 134 -1 124 3L22 55C8 61 0 75 0 91V227C0 243 10 259 26 265L164 317C174 321 186 321 196 317zM240 196.14V122L200 106V181.14L160 166.14V45.782L280 91V211.14L240 196.14z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Package.defaultProps = {
    size: IconSize.STANDARD,
};
Package.displayName = `Blueprint5.Icon.Package`;
export default Package;
