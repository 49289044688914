import { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';
import QRcode from 'qrcode';

import { Honeybadger } from '@honeybadger-io/react';

import {
	setPosPrinterConnectedStatus,
	enqueueInvoiceOnPosPrinterQueue,
	requeueInvoiceOnPosPrinterQueue,
	removeInvoiceFromPosPrinterQueue,
	setPrinters,
} from '../slices/AppSlice';

import {
	loadInvoice,
} from '../api/Business';

import PrintHelper   from '../helpers/Print';
import InvoiceHelper from '../helpers/Invoice';
//import ErrorReportingHelper from '../helpers/ErrorReportingHelper';
import LocalStorageHelper from '../helpers/LocalStorageHelper';

class PosPrinterWatcher extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			current_item: null,
		};
		
		this.wait_for_remote_confirmation = false;
		
		this.init                           = this.init                          .bind(this);
		this.cleanup                        = this.cleanup                       .bind(this);
		this.processQueue                   = this.processQueue                  .bind(this);
		this.generatePrintItemsForWidthTest = this.generatePrintItemsForWidthTest.bind(this);
		this.generatePrintItemsForInvoice   = this.generatePrintItemsForInvoice  .bind(this);
	}
	
	componentWillUnmount() {
		this.cleanup();
	}
	
	init() {
		console.log('init print ws');
		
		const ws = new WebSocket(this.props.ws_print_url);
		ws.onmessage = event => {
			console.log({ op: 'ws_onmessage', event });
			const data = JSON.parse(event.data);
			if (data.Action == 'raw-print') {
				const base64_encoded_print = data.Data;
				console.log('converting POS raw to HTML');
				fetch(this.props.api_url + 'escpos/convert_to_html.php', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: new URLSearchParams({
						'data':          base64_encoded_print,
						'printer_width': LocalStorageHelper.GetValue(this.props.client + '__pos_printer_width'),
					}),
				}).then(response => {
					console.log({ response });
					return response.text();
				}).then(response => {
					const win = window.open('', 'Title');
					if (win === null || win === undefined) {
						//TODO display a message saying to allow popups
						return;
					}
					win.document.body.innerHTML = response;
				});
			}
			else if (data.Action == 'printers') {
				this.props.setPrinters(JSON.parse(data.Data));
			}
			else if (data.Action == 'version') {
				console.log([ 'Got back version:', data.Data ]);
				const version = parseInt(data.Data, 10);
				if (version >= 1004) {
					this.wait_for_remote_confirmation = true;
				}
				
				this.processQueue();
			}
			else if (data.Action == 'completed-print') {
				this.props.removeInvoiceFromPosPrinterQueue();
				
				/*if (item.type == 'invoice') {
					ErrorReportingHelper.ReportPosPrintInfo(
						JSON.stringify({ op: 'PosPrinterWatcher.processQueue: step finished', ts: (new Date()).toISOString(), id_invoice: item.invoice.id_invoice }),
						{ tags: 'pos_print, step_finished, item_' + item.invoice.id_invoice }
					);
				}*/
				
				this.setState({ current_item: null });
				
				this.processQueue();
			}
		};
		ws.onopen = event => {
			this.props.setPosPrinterConnectedStatus(true);
			
			if (ws.readyState == WebSocket.OPEN) {
				ws.send(JSON.stringify({
					Action: 'get-printers',
					Data:   '',
				}));
				ws.send(JSON.stringify({
					Action: 'get-version',
					Data:   '',
				}));
			}
		};
		ws.onclose = event => {
			this.props.setPosPrinterConnectedStatus(false);
			setTimeout(() => {
				this.ws = this.init();
			}, 5000);
		};
		
		return ws;
	}
	
	cleanup() {
		if (this.ws !== undefined && this.ws !== null) {
			// prevent auto reconnect
			this.ws.onclose = event => {};
			this.ws.close();
			
			this.props.setPosPrinterConnectedStatus(false);
		}
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.props.ws_print_url != prevProps.ws_print_url) {
			this.cleanup();
			this.ws = this.init();
		}
		
		if (this.props.pos_printer_queue != prevProps.pos_printer_queue || this.state.current_item != prevState.current_item) {
			this.processQueue();
		}
	}
	
	componentDidMount() {
		if (this.props.ws_print_url !== null) {
			this.ws = this.init();
		}
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		let rounded_val = Math.properRound(parseFloat(val), decimal_digit_count);
		
		return rounded_val
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	fitColumns(printer_width, columns) {
		let fit_columns = [...columns];
		
		// figure out how many columns we can fit
		let column_width_sum = 0;
		for (let i=0; i<fit_columns.length; i++) {
			column_width_sum += fit_columns[i].min_width;
			if (column_width_sum <= printer_width) {
				fit_columns[i].width = fit_columns[i].min_width;
				column_width_sum += 1; // spacing between columns
			}
		}
		
		// figure out best fit for columns
		let visible_column_count     = fit_columns.filter(x => x.width > 0).length;
		let visible_column_width_sum = fit_columns.reduce((acc, cur) => acc + cur.width, 0);
		let available_column_width   = printer_width - (visible_column_count - 1);
		
		if (available_column_width > visible_column_width_sum) {
			// there is more space available than visible columns require, let's figure out best fit
			column_width_sum = 0;
			for (let i=0; i<fit_columns.length; i++) {
				if (fit_columns[i].width == 0) continue;
				
				let column_width = Math.round(fit_columns[i].width / visible_column_width_sum * available_column_width);
				// check if rounding put our column outside available space
				if (column_width + column_width_sum > available_column_width) {
					column_width = available_column_width - column_width_sum;
				}
				column_width_sum += column_width;
				
				fit_columns[i].width = column_width;
			}
		}
		
		return fit_columns;
	}
	
	async processQueue() {
		if (this.state.current_item !== null || this.props.pos_printer_queue.length == 0) {
			return;
		}
		if (this.ws.readyState != WebSocket.OPEN) {
			return;
		}
		
		let item = this.props.pos_printer_queue[0];
		console.log({ op: 'PosPrinterWatcher.processQueue', item });
		
		if (item === undefined) {
			Honeybadger.notify('PosPrinterWatcher, item is undefined', { name: 'Warning' });
			this.props.removeInvoiceFromPosPrinterQueue();
			return;
		}
		
		this.setState({ current_item: item });
		
		let print_items = [];
		if (item.type == 'invoice') {
			/*ErrorReportingHelper.ReportPosPrintInfo(
				JSON.stringify({ op: 'PosPrinterWatcher.processQueue: step 2', ts: (new Date()).toISOString(), id_invoice: item.invoice.id_invoice }),
				{ tags: 'pos_print, step_2, item_' + item.invoice.id_invoice }
			);*/
			
			print_items = await this.generateInvoice(item.invoice);
			
			/*ErrorReportingHelper.ReportPosPrintInfo(
				JSON.stringify({ op: 'PosPrinterWatcher.processQueue: step 3', ts: (new Date()).toISOString(), id_invoice: item.invoice.id_invoice }),
				{ tags: 'pos_print, step_3, item_' + item.invoice.id_invoice }
			);*/
		}
		else if (item.type == 'activities') {
			print_items = this.generateActivities(
				item.item,
				item.date,
				item.time_slot,
				item.activity_reservations,
			);
		}
		else if (item.type == 'available_invoices') {
			print_items = this.generateAvailableAccommodations(
				item.grouped,
				item.from,
				item.to,
				item.language
			);
		}
		
		const settings_printer_name  = LocalStorageHelper.GetValue(this.props.client + '__pos_printer_name');
		const settings_printer_width = LocalStorageHelper.GetValue(this.props.client + '__pos_printer_width');
		
		//console.log(print_items.filter(x => x !== null).join('\n'));
		console.log({ op: 'PosPrinterWatcher.processQueue sending to helper' });
		console.log({
			ws_readyState: this.ws.readyState,
			print_items,
		});
		if (this.ws.readyState == WebSocket.OPEN) {
			const msg = {
				Action: 'print',
				Data: JSON.stringify({
					PrinterName:  settings_printer_name == 'HTML' ? 'raw' : settings_printer_name,
					PrinterWidth: settings_printer_width,
					Contents:     print_items.filter(x => x !== null).join('\n'),
				}),
			};
			this.ws.send(JSON.stringify(msg));
			
			console.log({ op: 'PosPrinterWatcher.processQueue sent to helper', contents: msg });
			
			/*if (item.type == 'invoice') {
				ErrorReportingHelper.ReportPosPrintInfo(
					JSON.stringify({ op: 'PosPrinterWatcher.processQueue: step 4', ts: (new Date()).toISOString(), id_invoice: item.invoice.id_invoice }),
					{ tags: 'pos_print, step_4, item_' + item.invoice.id_invoice }
				);
			}*/
		}
		else {
			// wait for connection
			this.setState({ current_item: null });
			return;
		}
	
		if (false) {
			//this.props.requeueInvoiceOnPosPrinterQueue(invoice);
		}
		
		if (!this.wait_for_remote_confirmation) {
			this.props.removeInvoiceFromPosPrinterQueue();
			this.setState({ current_item: null });
			
			//this.processQueue();
		}
	}
	
	async generateInvoice(invoice) {
		console.log({ op: 'PosPrinterWatcher.generateInvoice step 1' });
		if (invoice.id_invoice !== undefined) {
			invoice = await loadInvoice(this.props.api_url, invoice.id_invoice, this.props.dispatch, this.props.token);
			invoice = {
				...invoice,
				type: 'regular',
			};
		}
		console.log({ op: 'PosPrinterWatcher.generateInvoice step 2' });
		
		// print
		console.log('printing POS');
		let print_items = [];
		if (invoice.type == 'test-width') {
			print_items = this.generatePrintItemsForWidthTest(invoice);
		}
		else {
			print_items = await this.generatePrintItemsForInvoice(invoice);
		}
		console.log({ op: 'PosPrinterWatcher.generateInvoice step 3' });
		console.log({
			op: 'print items length',
			len: print_items.length,
		});
		
		return print_items;
	}
	
	generateActivities(item, date, time_slot, activity_reservations) {
		console.log({ item, date, time_slot, activity_reservations });
		let print_items = [];
		
		const printer_width = LocalStorageHelper.GetValue(this.props.client + '__pos_printer_width');
		
		for (let id_activity in activity_reservations) {
			if (activity_reservations[id_activity].length == 0) continue;
			
			let activity_reservation = null;
			for (let i=0; i<item.activities.length; i++) {
				if (item.activities[i].key == id_activity) {
					activity_reservation = item.activities[i];
				}
			}
			if (activity_reservation === null) continue;
			
			print_items.push('Tip rezervacije: ' + activity_reservation.title);
			
			print_items.push('<newline>');
			print_items.push('<newline>');
			
			const date_line = 'Datum: ' + moment(date).format('DD.MM.YYYY');
			print_items.push(date_line);
			print_items.push(('Termin ob: ' + item.time_label).padStart(printer_width - date_line.length));
			
			print_items.push('<newline>');
			print_items.push('<hr>');
			print_items.push('<newline>');
			
			const item_columns = [
				{ width: 0, line1: 'Nosilec' },
				{ width: 7, line1: 'Država'  },
				{ width: 4, line1: 'Pl.'     },
				{ width: 7, line1: 'Osebe'   },
			];
			item_columns[0].width = printer_width - item_columns[1].width - item_columns[2].width - item_columns[3].width;
			
			// add items column headers
			for (let i=0; i<item_columns.length; i++) {
				const width = item_columns[i].width;
				if (width == 0) continue;
				
				print_items.push(item_columns[i].line1.padEnd(width));
			}
			
			print_items.push('<newline>');
			print_items.push('<hr>');
			print_items.push('<newline>');
			
			let count = 0;
			for (let i=0; i<activity_reservations[id_activity].length; i++) {
				const reservation = activity_reservations[id_activity][i];
				
				const customer      = this.props.customers[reservation.id_customer];
				let   customer_name = customer.type == 'natural' ? customer.surname + ' ' + customer.name : customer.company_name;
				if (customer_name.length > item_columns[0].width) {
					customer_name = customer_name.substring(0, item_columns[0].width);
				}
				
				const country       = this.props.countries[customer.id_country];
				let   country_title = country.iso_3166_1_a2;
				
				const paid = reservation.status == 'closed';
				
				print_items.push(customer_name                                                    .padEnd  (item_columns[0].width));
				print_items.push(country_title                                                    .padEnd  (item_columns[1].width));
				print_items.push((paid ? 'DA' : 'NE')                                             .padEnd  (item_columns[2].width));
				print_items.push((reservation.guest_count + ' / ' + reservation.guest_child_count).padStart(item_columns[3].width));
				
				print_items.push('<newline>');
				
				if (customer.phone != '') {
					print_items.push('   T: ' + customer.phone);
					print_items.push('<newline>');
				}
				
				count += reservation.guest_count + reservation.guest_child_count;
			}
			
			print_items.push('<hr>');
			print_items.push('<newline>');
			
			print_items.push('Skupaj:'   .padStart(printer_width - item_columns[3].width));
			print_items.push((count + '').padStart(item_columns[3].width));
			
			print_items.push('<newline>');
			print_items.push('<newline>');
			print_items.push('<newline>');
		}
		
		return print_items;
	}
	
	generateAvailableAccommodations(grouped, from, to, language) {
		const trans = {
			title:  { 'en': 'Available places', 'sl': 'Prosta mesta', 'de': 'Freie Plätze' },
			period: { 'en': 'Period', 'sl': 'Obdobje', 'de': 'Zeitraum' },
		};
		
		let print_items = [];
		
		const printer_width = LocalStorageHelper.GetValue(this.props.client + '__pos_printer_width');
		
		print_items.push('<center>');
		
		print_items.push(trans.title[language]);
		print_items.push('<newline>');
		print_items.push('<newline>');
		
		print_items.push(trans.period[language] + ': ' + moment(from).format('DD.MM.') + ' - ' + moment(to).format('DD.MM.'));
		print_items.push('<newline>');
		print_items.push('<newline>');
		
		for (let id_accommodation in grouped) {
			print_items.push(grouped[id_accommodation].accommodation.title);
			print_items.push('<newline>');
			
			for (let id_accommodation_item in grouped[id_accommodation].accommodation_items) {
				const item = grouped[id_accommodation].accommodation_items[id_accommodation_item];
				
				if (item.accommodation_item.title != 'default') {
					print_items.push(item.accommodation_item.title);
					print_items.push('<newline>');
				}
				
				let line = '';
				for (let i=0; i<item.accommodation_item_places.length; i++) {
					console.log({
						accommodation_item_place: item.accommodation_item_places[i],
					});
					const title = item.accommodation_item_places[i].title + '   ';
					if ((line + title).length > printer_width) {
						print_items.push(line.trim());
						print_items.push('<newline>');
						line = '';
					}
					line += title;
				}
				
				line = line.trim();
				if (line.length > 0) {
					print_items.push(line);
				}
				print_items.push('<newline>');
				print_items.push('<hr>');
				print_items.push('<newline>');
			}
		}
		
		return print_items;
	}
	
	generatePrintItemsForWidthTest(item) {
		let line = '';
		for (let i=0; i<item.width; i++) {
			line += i % 10;
		}
		return [ line, '<newline>' ];
	}
	async generatePrintItemsForInvoice(invoice) {
		console.log({ op: 'PosPrinterWatcher.generatePrintItemsForInvoice at start' });
		const general_settings = this.props.general_settings;
		
		const document_type = this.props.document_types[invoice.invoice_type];
		
		const invoice_fiscal_verification = invoice.id_invoice_fiscal_verification === null ? null :
			(this.props.invoice_fiscal_verifications[invoice.id_invoice_fiscal_verification] || null);
		//TODO load invoice fiscal verification if it's not loaded yet
		
		let qr_value = null;
		if (invoice_fiscal_verification !== null && invoice_fiscal_verification.signed_zoi !== null) {
			qr_value = (
				PrintHelper.BigNumberHexToDecimal(invoice_fiscal_verification.signed_zoi).padStart(39, '0') +
				invoice_fiscal_verification.tax_number +
				moment(invoice.issued).format('YYYYMMDDHHmmss')
			);
			qr_value += PrintHelper.CalculateModulo10(qr_value);
		}
		
		const printer_width = LocalStorageHelper.GetValue(this.props.client + '__pos_printer_width');
		
		const itemOrEmpty = (value, condition) => {
			if (condition === undefined) condition = value !== null && value.length > 0;
			
			if (!condition) {
				return [];
			}
			return [ '<newline>', value ];
		};
		
		let customer_address = invoice.customer_address;
		if (
			customer_address !== null &&
			customer_address !== undefined &&
			customer_address.length > 0 && (invoice.customer_post_code.length > 0 || invoice.customer_post_office.length > 0)
		) {
			customer_address += ', ';
			customer_address += ((invoice.customer_post_code ?? '') + ' ' + (invoice.customer_post_office ?? '')).trim();
		}
		
		const cash_register    = this.props.cash_registers[invoice.id_cash_register] || null;
		const business_premise = cash_register === null ? null : (this.props.business_premises[cash_register.id_business_premise] || null);
		
		let place = '';
		if (business_premise !== null && business_premise.type == '1') {
			place = business_premise.town;
		}
		else {
			place = general_settings.subject_city;
		}
		
		let timestamp       = moment(invoice.issued).format('DD.MM.YYYY \\o\\b HH:mm:ss');
		let place_with_time = '';
		if (place.length > 0) {
			place_with_time += place + ', ';
		}
		place_with_time += timestamp;
		
		const place_with_time_items = [];
		// if place + timestamp is too long, separate them into two lines
		if (place_with_time.length > printer_width) {
			if (place.length > 0) {
				place_with_time_items.push(place);
				place_with_time_items.push('<newline>');
			}
			place_with_time_items.push(timestamp);
			place_with_time_items.push('<newline>');
		}
		else {
			place_with_time_items.push(place_with_time);
			place_with_time_items.push('<newline>');
		}
		
		let customer_name = (
			(invoice.customer_name ?? '')
			+ ' ' +
			(invoice.customer_surname ?? '')
		).trim();
		if (invoice.customer_type == 'company') {
			if (invoice.customer_display_company_long_name_on_invoices && invoice.customer_company_long_name.length > 0) {
				customer_name = invoice.customer_company_long_name ?? '';
			}
			else {
				customer_name = invoice.customer_company_name ?? '';
			}
		}
		
		const logo_width = printer_width <= 32 ? printer_width : Math.min(40, Math.round(0.9 * printer_width));
		
		let customer_data_items = [
			...itemOrEmpty(customer_name.trim()),
			...itemOrEmpty(customer_address),
			...itemOrEmpty(
				(invoice.customer_vat_registered ? 'ID za DDV: ' : 'Davčna št.: ') + invoice.customer_vat_prefix + invoice.customer_tax_number,
				((invoice.customer_vat_prefix ?? '') + (invoice.customer_tax_number ?? '')).trim().length > 0
			),
		];
		
		let print_items = [
			'<center>',
			
			(general_settings.logo_pos === undefined || general_settings.logo_pos === null ? null : '<logo>'),
			(general_settings.logo_pos === undefined || general_settings.logo_pos === null ? null : logo_width),
			(general_settings.logo_pos === undefined || general_settings.logo_pos === null ? null : general_settings.logo_pos),
			
			'<center>',
			...itemOrEmpty(general_settings.subject_short_name),
			...itemOrEmpty(general_settings.subject_address),
			...itemOrEmpty((general_settings.subject_post_code + ' ' + general_settings.subject_city).trim()),
			...itemOrEmpty(
				(general_settings.subject_vat_registered ? 'ID za DDV: ' : 'Davčna št.: ') + general_settings.subject_tax_number,
				general_settings.subject_tax_number.length > 0
			),
			...itemOrEmpty(
				(
					(general_settings.subject_email.length == 0 ? '' : 'E: ' + general_settings.subject_email)
					+ ' ' +
					(general_settings.subject_website.length == 0 ? '' : 'W: ' + general_settings.subject_website)
				).trim()
			),
			...itemOrEmpty(
				(
					(general_settings.subject_phone_1.length == 0 ? '' : 'T: ' + general_settings.subject_phone_1)
					+ ' ' +
					(general_settings.subject_phone_2.length == 0 ? '' : 'M: ' + general_settings.subject_phone_2)
				).trim()
			),
			'<newline>',
			'<newline>',
			'<left>',
			'<enablebold>',
			'<newline>', 'RAČUN ŠT.: ' + invoice.invoice_number,
			'<disablebold>',
			'<newline>',
			...itemOrEmpty('Podatki o stranki:', customer_data_items.length > 0),
			...customer_data_items,
			...itemOrEmpty('<newline>',          customer_data_items.length > 0),
			'<newline>',
			...place_with_time_items,
			'<hr>',
			'<newline>',
			'Šifra'.padEnd(8),
			'Opis',
			'<newline>',
		];
		
		const item_columns = this.fitColumns(printer_width, [
			{ min_width: 3, width: 0, line1: 'Kol',      line2: ''        },
			{ min_width: 7, width: 0, line1: 'Cena',     line2: 'z DDV'   },
			{ min_width: 6, width: 0, line1: 'Popust',   line2: '%'       },
			{ min_width: 8, width: 0, line1: 'Vrednost', line2: 'br. DDV' },
			{ min_width: 4, width: 0, line1: 'DDV',      line2: '%'       },
			{ min_width: 8, width: 0, line1: 'Vrednost', line2: 'z DDV'   },
		]);
		
		// add invoice items column headers
		for (let line = 0; line < 2; line++) {
			for (let i=0; i<item_columns.length; i++) {
				if (item_columns[i].width == 0) continue;
				
				const width = item_columns[i].width;
				
				let val = (
					line == 0 ? item_columns[i].line1 : item_columns[i].line2
				).padStart(width);
				
				// add spacing, but not for last item
				if (
					i < (item_columns.length - 1) // not last item in array
					&&
					item_columns[i + 1].width > 0 // next item is visible
				) {
					val = val.padEnd(width + 1);
				}
				
				print_items.push(val);
			}
			
			print_items.push('<newline>');
		}
		
		print_items.push('<hr>');
		print_items.push('<newline>');
		
		// add invoice items
		let tt_item_exists = false;
		for (let i=0; i<invoice.items.length; i++) {
			const invoice_item = invoice.items[i];
			
			const internal_code_width = 8;
			const title_width         = printer_width - internal_code_width;
			if (
				invoice_item.item_internal_code.length < internal_code_width ||
				invoice_item.item_title        .length > title_width
			) {
				print_items.push(invoice_item.item_internal_code.padEnd(internal_code_width));
				print_items.push(invoice_item.item_title.padEnd(title_width).slice(0, title_width));
			}
			else {
				print_items.push(invoice_item.item_internal_code);
				print_items.push('<newline>');
				print_items.push(invoice_item.item_title);
			}
			print_items.push('<newline>');
			
			const columns = [
				this.formatNumber(invoice_item.quantity, 0),
				this.formatNumber(invoice_item.price,    2),
				this.formatNumber(invoice_item.discount, 2),
				this.formatNumber(invoice_item.quantity * invoice_item.price * (1 - invoice_item.discount / 100) / (1 + invoice_item.tax_rate / 100), 2),
				this.formatNumber(invoice_item.tax_rate, 1),
				this.formatNumber(invoice_item.quantity * invoice_item.price * (1 - invoice_item.discount / 100), 2),
			];
			for (let j=0; j<columns.length; j++) {
				if (item_columns[j].width == 0) continue;
				
				const width = item_columns[j].width;
				let   val   = columns[j].padStart(width);
				
				// add spacing, but not for last item
				if (
					j < (item_columns.length - 1) // not last item in array
					&&
					item_columns[j + 1].width > 0 // next item is visible
				) {
					val = val.padEnd(width + 1);
				}
				
				print_items.push(val);
			}
			
			print_items.push('<newline>');
			
			if (invoice_item.item_type == 'tourist_tax') {
				tt_item_exists = true;
			}
		}
		
		let invoice_user = null;
		for (let id_user in this.props.users) {
			if (this.props.users[id_user].username == invoice.username) {
				invoice_user = this.props.users[id_user];
				break;
			}
		}
		
		// taxes
		const {
			invoice_items_by_tax_rate,
			invoice_items_taxes_sums,
		} = InvoiceHelper.groupInvoiceItemsByTaxes(
			invoice,
			this.props.id_advance_invoice_consumption_by_id_consumer_invoices,
			this.props.advance_invoice_consumptions
		);
		
		print_items = print_items.concat([
			'<hr>',
			'<newline>',
		]);
		
		if (invoice_items_taxes_sums[4] != 0) {
			print_items = print_items.concat([
				'Vrednost brez DDV EUR'.padStart(printer_width - 10),
				this.formatNumber(invoice_items_taxes_sums[3], 2).padStart(10),
				'<newline>',
				'Popust EUR'.padStart(printer_width - 10),
				this.formatNumber(-invoice_items_taxes_sums[4], 2).padStart(10),
				'<newline>',
			]);
		}
		
		print_items = print_items.concat([
			'Davčna osnova EUR'.padStart(printer_width - 10),
			this.formatNumber(invoice_items_taxes_sums[0], 2).padStart(10),
			'<newline>',
			'Znesek davka EUR'.padStart(printer_width - 10),
			this.formatNumber(invoice_items_taxes_sums[1], 2).padStart(10),
			'<newline>',
			'<enablebold>',
			'Skupaj za plačilo EUR'.padStart(printer_width - 10),
			this.formatNumber(invoice_items_taxes_sums[2], 2).padStart(10),
			'<disablebold>',
			'<newline>',
			'<newline>',
			'<newline>',
		]);
		
		if (Object.keys(invoice_items_by_tax_rate).length > 0) {
			const grouped_taxes_columns = this.fitColumns(printer_width, [
				{ min_width: 7, width: 0, value: 'Stopnja' },
				{ min_width: 9, width: 0, value: 'Osnova'  },
				{ min_width: 9, width: 0, value: 'Davek'   },
				{ min_width: 9, width: 0, value: 'Skupaj'  },
			]);
			
			// add grouped taxes column headers
			for (let i=0; i<grouped_taxes_columns.length; i++) {
				const width = grouped_taxes_columns[i].width;
				if (width == 0) continue;
				
				let val = grouped_taxes_columns[i].value.padStart(width);
				
				// add spacing, but not for last item
				if (
					i < (grouped_taxes_columns.length - 1) // not last item in array
					&&
					grouped_taxes_columns[i + 1].width > 0 // next item is visible
				) val = val.padEnd(width + 1);
				
				print_items.push(val);
			}
			
			print_items.push('<newline>');
			print_items.push('<hr>');
			print_items.push('<newline>');
			
			let taxes = [];
			let adjusted_tax_sums = [ 0, 0, 0 ];
			const invoice_items_by_tax_rate_keys = Object.keys(invoice_items_by_tax_rate).sort((a, b) => {
				if (parseFloat(a) < parseFloat(b)) return -1;
				if (parseFloat(a) > parseFloat(b)) return  1;
				return 0;
			});
			for (let i=0; i<invoice_items_by_tax_rate_keys.length; i++) {
				const key          = invoice_items_by_tax_rate_keys[i];
				const tax_rate     = parseFloat(invoice_items_by_tax_rate[key].tax_rate);
				const price        = parseFloat(invoice_items_by_tax_rate[key].partial_price_discounted_without_tax);
				const tax_amount   = parseFloat(invoice_items_by_tax_rate[key].partial_price_tax_amount);
				const total_amount = parseFloat(invoice_items_by_tax_rate[key].partial_price_discounted);
				
				adjusted_tax_sums[0] += price;
				adjusted_tax_sums[1] += tax_amount;
				adjusted_tax_sums[2] += total_amount;
				
				taxes.push([
					key == -2 ? 'neobd.' : this.formatNumber(tax_rate, 1) + '%',
					this.formatNumber(price,        2),
					this.formatNumber(tax_amount,   2),
					this.formatNumber(total_amount, 2),
				]);
			}
			
			taxes.push([
				'',
				this.formatNumber(adjusted_tax_sums[0], 2),
				this.formatNumber(adjusted_tax_sums[1], 2),
				this.formatNumber(adjusted_tax_sums[2], 2),
			]);
			
			for (let j=0; j<taxes.length; j++) {
				if (j == (taxes.length - 1)) {
					print_items.push('<hr>');
					print_items.push('<newline>');
				}
				
				for (let i=0; i<grouped_taxes_columns.length; i++) {
					const width = grouped_taxes_columns[i].width;
					if (width == 0) continue;
					
					let val = taxes[j][i].padStart(width);
					
					// add spacing, but not for last item
					if (
						i < (grouped_taxes_columns.length - 1) // not last item in array
						&&
						grouped_taxes_columns[i + 1].width > 0 // next item is visible
					) val = val.padEnd(width + 1);
					
					print_items.push(val);
				}
				
				print_items.push('<newline>');
			}
		}
		
		// payment types
		let payment_types_sums = {};
		for (let i=0; i<invoice.payments.length; i++) {
			const invoice_payment = invoice.payments[i];
			
			const price = parseFloat(invoice_payment.amount);
			
			let key = invoice_payment.id_payment_type;
			if (payment_types_sums[key] === undefined) {
				payment_types_sums[key] = { price: 0 };
			}
			payment_types_sums[key].price += price;
		}
		
		let payment_types = [];
		for (let key in payment_types_sums) {
			const price        = payment_types_sums[key].price;
			const payment_type = this.props.payment_types[key];
			
			payment_types.push(payment_type.title.padEnd(20));
			payment_types.push(this.formatNumber(price, 2).padStart(Math.min(12, printer_width - 24)));
			payment_types.push('<newline>');
		}
		
		if (invoice.advance_payment_amount != 0) {
			payment_types.push('Avans'.padEnd(20));
			payment_types.push(this.formatNumber(invoice.advance_payment_amount, 2).padStart(Math.min(12, printer_width - 24)));
			payment_types.push('<newline>');
			
			const advance_invoices = this.props.id_advance_invoice_consumption_by_id_consumer_invoices[invoice.id_invoice] ?? [];
			const advance_invoice_numbers = advance_invoices.map(
				x => this.props.invoices[this.props.advance_invoice_consumptions[x].advance_id_invoice].invoice_number
			);
			payment_types.push(advance_invoice_numbers.join(', '));
			payment_types.push('<newline>');
		}
		
		let qr_code = qr_value === null ? null :
			await QRcode.toDataURL(qr_value, { errorCorrectionLevel: 'M', version: 4 });
		;
		if (qr_code !== null && qr_code.indexOf('data:image/png;base64,') == 0) {
			qr_code = qr_code.substring('data:image/png;base64,'.length);
		}
		
		print_items = print_items.concat([
			'<newline>',
			'<newline>',
			'Način plačila:',
			'<newline>',
			...payment_types,
			
			'<newline>',
			...itemOrEmpty(
				'Blagajnik: ' + (invoice_user === null ? '' : (invoice_user.name + ' ' + (
					invoice_user.surname.length == 0 ? '' : (invoice_user.surname.substring(0, 1) + '.')
				)).trim()),
				invoice_user !== null
			),
			'<newline>',
			'Interna št.: ' + invoice.invoice_number_internal,
			...itemOrEmpty('Kopija: ' + invoice.copy_count, invoice.copy_count > 0),
			'<newline>',
			'<newline>',
			'ZOI: ' + (invoice_fiscal_verification === null ? '' : (invoice_fiscal_verification.signed_zoi ?? '')),
			'<newline>',
			'EOR: ' + (invoice_fiscal_verification === null ? '' : (invoice_fiscal_verification.eor        ?? '')),
			'<newline>',
			'<newline>',
			
			'<center>',
			(qr_code === null ? null : '<logo>'),
			(qr_code === null ? null : Math.min(16, printer_width)),
			(qr_code === null ? null : qr_code),
		]);
		
		// add VAT reason text
		if (general_settings.subject_vat_registered === false) {
			const vat_reason_items = [
				'<left>',
			];
			const arr = (general_settings.subject_vat_registered_reason || '').split('\n');
			
			for (let i=0; i<arr.length; i++) {
				vat_reason_items.push('<newline>');
				vat_reason_items.push(arr[i]);
			}
			vat_reason_items.push('<newline>');
			
			print_items = print_items.concat(vat_reason_items);
		}
		
		// add tourist tax text
		if (tt_item_exists) {
			const tt_items = [
				'<left>',
			];
			const arr = (general_settings.tourist_tax_text || '').split('\n');
			
			for (let i=0; i<arr.length; i++) {
				tt_items.push('<newline>');
				tt_items.push(arr[i]);
			}
			tt_items.push('<newline>');
			
			print_items = print_items.concat(tt_items);
		}
		
		// add final text
		if (document_type !== null && document_type !== undefined) {
			let print_final_text = document_type.print_final_text_pos;
			
			print_items.push('<left>');
			if (print_final_text !== null && print_final_text !== undefined) {
				const arr = print_final_text.split('\n');
				for (let i=0; i<arr.length; i++) {
					print_items.push('<newline>');
					if (arr[i] == '<right>') {
						print_items.push('<right>');
					}
					else if (arr[i] == '</right>') {
						print_items.push('<left>');
					}
					else {
						print_items.push(arr[i]);
					}
				}
			}
		}
		
		console.log({ op: 'PosPrinterWatcher.generatePrintItemsForInvoice at end' });
		return print_items;
	}
	
	render() {
		return null;
	}
}
export default connect(
	state => {
		return {
			api_url:                                                state.ConstantsSlice.api_url,
			ws_print_url:                                           state.ConstantsSlice.ws_print_url,
			client:                                                 state.ConstantsSlice.client,
			pos_printer_queue:                                      state.AppSlice.pos_printer_queue,
			general_settings:                                       state.SettingsSlice.general,
			users:                                                  state.UserSlice.users,
			token:                                                  state.UserSlice.token,
			cash_registers:                                         state.CashRegisterSlice.cash_registers,
			business_premises:                                      state.CodeTablesSlice.business_premises,
			payment_types:                                          state.CodeTablesSlice.payment_types,
			customers:                                              state.CodeTablesSlice.customers,
			countries:                                              state.CodeTablesSlice.countries,
			document_types:                                         state.DocumentSlice.document_types,
			invoices:                                               state.BusinessSlice.invoices,
			invoice_fiscal_verifications:                           state.BusinessSlice.invoice_fiscal_verifications,
			advance_invoice_consumptions:                           state.BusinessSlice.advance_invoice_consumptions,
			id_advance_invoice_consumption_by_id_consumer_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
		};
	},
	dispatch => {
		return {
			dispatch,
			setPosPrinterConnectedStatus:     connected => dispatch(setPosPrinterConnectedStatus({ connected })),
			enqueueInvoiceOnPosPrinterQueue:  data      => dispatch(enqueueInvoiceOnPosPrinterQueue(data)),
			requeueInvoiceOnPosPrinterQueue:  data      => dispatch(requeueInvoiceOnPosPrinterQueue(data)),
			removeInvoiceFromPosPrinterQueue: data      => dispatch(removeInvoiceFromPosPrinterQueue(data)),
			setPrinters:                      data      => dispatch(setPrinters({ printers: data })),
		};
	},
)(PosPrinterWatcher);
