/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const ThAdd: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="th-add" ref={ref}  {...props}>
            <path
                d={isLarge ? "M300 380C300 391.04584 308.956 400 320 400S340 391.04584 340 380V340H380C391.044 340 400 331.0458 400 320S391.044 300 380 300H340V260C340 248.9542 331.044 240 320 240S300 248.9542 300 260V300H260C248.956 300 240 308.9542 240 320S248.956 340 260 340H300V380zM380 260V40C380 28.954 371.044 20 360 20H20C8.95508 20 0 28.954 0 40V360C0 370 7.99804 380 20 380H260C226.866 380 200 353.1372 200 320C200 304.6328 205.776 290.6152 215.278 280H160V220H275.278C286.264 207.725 302.232 200 320 200C327.012 200 333.746 201.203 340 203.4144C340.528 203.6004 341.05 203.794 341.568 203.9944C342.168 204.2254 342.764 204.4656 343.354 204.715C364.888 213.8236 380 235.147 380 260zM320 200H160V140H340V200H320zM140 220V280H40V220H140zM40 200V140H140V200H40zM40 120V60H140V120H40zM340 120H160V60H340V120z" : "M260 320C248.954 320 240 311.0457 240 300V280H220C208.954 280 200 271.0456 200 260S208.954 240 220 240H240V220C240 208.9544 248.954 200 260 200S280 208.9544 280 220V240H300C311.046 240 320 248.9544 320 260S311.046 280 300 280H280V300C280 311.0457 271.046 320 260 320zM175.2782 220H140V180H215.278C226.264 167.725 242.23 160 260 160C287.076 160 309.964 177.9342 317.43 202.5714C318.2960000000001 202.8336 319.1520000000001 203.1148 320 203.4146V40C320 28 312 20 300 20H20C8 20 0 28 0 40V280C0 290 8 300 20 300H175.2776C165.777 289.385 160 275.3674 160 260.0004C160 244.633 165.7772 230.6152 175.2782 220zM120 220H40V180H120V220zM120 160H40V120H120V160zM140 120H280V160H140V120zM120 100H40V60H120V100zM140 60H280V100H140V60z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
ThAdd.defaultProps = {
    size: IconSize.STANDARD,
};
ThAdd.displayName = `Blueprint5.Icon.ThAdd`;
export default ThAdd;
