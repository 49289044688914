/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const ThVirtual: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="th-virtual" ref={ref}  {...props}>
            <path
                d={isLarge ? "M60 240C60 251.0458 68.955 260 80 260H180C191.045 260 200 251.0458 200 240S191.045 220 180 220H128.2862L234.14 114.144C241.9540000000001 106.334 241.9540000000001 93.67 234.14 85.86C226.3280000000001 78.048 213.666 78.048 205.854 85.86L100 191.714V140C100 128.954 91.045 120 80 120S60 128.954 60 140V240zM400 40C400 28.954 391.044 20 380 20H20C8.95508 20 0 28.954 0 40V360C0 370 7.99804 380 20 380H380C391.044 380 400 371.0456 400 360V40zM360 60V280H40V60H360z" : "M140 160C151.045 160 160 168.9542 160 180S151.045 200 140 200H80C68.955 200 60 191.0458 60 180V120C60 108.954 68.955 100 80 100S100 108.954 100 120V132.7466L147.212 85.59C154.6778 78.13 166.9092 78.138 174.3848 85.608C181.8652 93.076 181.8702 105.292 174.4042 112.752L127.1044 160H140zM20 300C7.99804 300 0 290 0 280V40C0 28.954 8.95508 20 20 20H300C312.002 20 320 28 320 40V280C320 291.0456 311.044 300 300 300H20zM280 60H40V220H280V60z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
ThVirtual.defaultProps = {
    size: IconSize.STANDARD,
};
ThVirtual.displayName = `Blueprint5.Icon.ThVirtual`;
export default ThVirtual;
