/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const ObjectView: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="object-view" ref={ref}  {...props}>
            <path
                d={isLarge ? "M100 400H20C8.9543 400 0 391.0457 0 380V300H40L40 360H100V400zM360 100V40H300V0H380C391.046 0 400 8.954 400 20V100H360zM0 100H40L40 40H100V0H20C8.9543 0 0 8.954 0 20V100zM300 400H380C391.046 400 400 391.0457 400 380V300H360V360H300V400zM191.0156 357.4978L74.8266 294.9616L200 220L325.1720000000001 294.9616L210 357.4978C203.852 360.834 197.1642 360.834 191.0156 357.4978zM190 199.998L62.115 280.0756C60.7466 277.48 60 274.5786 60 271.5756V146.008C60 139.192 63.8458 132.9 70.0772 129.518L187.855 61.006C188.5534 60.626 189.2696 60.29 190 59.998V199.998zM337.884 280.077C339.252 277.4812 339.998 274.5798 339.998 271.5768V146.008C339.998 139.194 336.1520000000001 132.9 329.922 129.5200000000001L212.146 61.006C211.448 60.628 210.73 60.292 210 59.998L209.998 199.998L337.884 280.077z" : "M20 320C8.9543 320 0 311.0457 0 300V240H40V280L80 280V320H20zM0 20V80H40L40 40H80V0H20C8.9543 0 0 8.954 0 20zM300 0H240V40H280V80H320V20C320 8.954 311.046 0 300 0zM320 300V240H280V280H240V320H300C311.046 320 320 311.0457 320 300zM159.9988 180L249.998 230L167.8382 278.1374C163.4922 280.6208000000001 156.9712 280.6208000000001 152.625 278.1374L71.4002 232.111L159.9988 180zM259.094 220C259.6140000000001 218.52 259.89 216.9432 259.89 215.3268V118.64C259.89 113.566 257.17 108.882 252.766 106.366L167.9998 60V163.0658L259.094 220zM151.8894 165.144L60.7946 220.0062C60.276 218.5262 60 215.4506 60 213.8342V120.7182C60 115.646 62.7184 110.96 67.123 108.444L151.8894 60.006V165.144z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
ObjectView.defaultProps = {
    size: IconSize.STANDARD,
};
ObjectView.displayName = `Blueprint5.Icon.ObjectView`;
export default ObjectView;
