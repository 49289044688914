/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const TagAdd: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="tag-add" ref={ref}  {...props}>
            <path
                d={isLarge ? "M300 380C311.046 380 320 371.0456 320 360V320H360C371.046 320 380 311.0456 380 300S371.046 280 360 280H320V240C320 228.9544 311.046 220 300 220S280 228.9544 280 240V280H240C228.954 280 220 288.9544 220 300S228.954 320 240 320H280V360C280 371.0456 288.954 380 300 380zM216.182 332.1394C206.364 324.8506 200 313.1684 200 300C200 277.9086 217.908 260 240 260H260V240C260 217.9086 277.908 200 300 200C313.168 200 324.85 206.3634 332.14 216.1828L348.23 200.0918C363.852 184.47 363.852 159.144 348.23 143.524L256.476 51.7699999999999C240.856 36.148 215.53 36.148 199.9082 51.7699999999999L51.7158 199.9619999999999A39.9994 39.9994 0 0 0 40 228.2462V320C40 342.0914 57.9086 360 80 360H171.7538A39.9994 39.9994 0 0 0 200.038 348.2842L216.182 332.1394zM79.8702 290.2272C79.8702 273.7124 93.258 260.3246000000001 109.7728 260.3246000000001S139.6754 273.7124 139.6754 290.2272S126.2876 320.1298 109.7728 320.1298S79.8702 306.742 79.8702 290.2272z" : "M240 320C251.046 320 260 311.0458 260 300V260H300C311.046 260 320 251.0456 320 240S311.046 220 300 220H260V180C260 168.9544 251.046 160 240 160S220 168.9544 220 180V220H180C168.9544 220 160 228.9544 160 240S168.9542 260 180 260H220V300C220 311.0458 228.954 320 240 320zM155.8408 271.8828C146.2158 264.578 140 253.015 140 240C140 217.9086 157.9086 200 180 200H200V180C200 157.9086 217.908 140 240 140C253.012 140 264.574 146.2132 271.878 155.8348C283.73 140.161 282.512 117.752 268.222 103.4620000000001L176.5378 11.778C160.9168 -3.8439999999999 135.5902 -3.8439999999999 119.9692 11.778L11.7158 120.0308A40 40 0 0 0 0 148.315V240C0 262.0914 17.9086 280 40 280H131.685C140.4556 280 148.929 277.1196 155.8408 271.8828zM39.8516 210.2596C39.8516 193.7524 53.2334 180.3708 69.7404 180.3708C86.2476 180.3708 99.6292 193.7524 99.6292 210.2596C99.6292 226.7666 86.2476 240.1484 69.7404 240.1484C53.2334 240.1484 39.8516 226.7666 39.8516 210.2596z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
TagAdd.defaultProps = {
    size: IconSize.STANDARD,
};
TagAdd.displayName = `Blueprint5.Icon.TagAdd`;
export default TagAdd;
