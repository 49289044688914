/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Server: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="server" ref={ref}  {...props}>
            <path
                d={isLarge ? "M275 400C288.808 400 300 388.8072 300 375V125C300 111.192 288.808 100 275 100H125C111.1928 100 100 111.192 100 125V375C100 388.8072 111.1928 400 125 400H275zM230 360C224.478 360 220 355.5228 220 350V330C220 324.4772000000001 224.478 320 230 320H250C255.522 320 260 324.4772000000001 260 330V350C260 355.5228 255.522 360 250 360H230zM220 270V250C220 244.4772 224.478 240 230 240H250C255.522 240 260 244.4772 260 250V270C260 275.5228 255.522 280 250 280H230C224.478 280 220 275.5228 220 270zM230 200C224.478 200 220 195.522 220 190V170C220 164.478 224.478 160 230 160H250C255.522 160 260 164.478 260 170V190C260 195.522 255.522 200 250 200H230zM80 185.482V125C80 100.148 100.1472 80 125 80H275C299.852 80 320 100.148 320 125V185.482L392.144 133.112C401.48 125.974 402.674 112.828 394.764 104.262L304.742 6.768C300.768 2.464 295.052 0 289.048 0H110.9528C104.9478 0 99.232 2.464 95.259 6.768L5.2364 104.262C-2.6736 112.828 -1.48 125.974 7.8556 133.112L80 185.482z" : "M220 320C231.046 320 240 311.0458 240 300V100C240 88.954 231.046 80 220 80H100C88.9544 80 80 88.954 80 100V300C80 311.0458 88.9544 320 100 320H220zM200 266.6666C200 274 194 280 186.6666 280H173.3334C166 280 160 274 160 266.6666V253.3334C160 246 166 240 173.3334 240H186.6666C194 240 200 246 200 253.3334V266.6666zM200 193.3334V206.6666C200 214 194 220 186.6666 220H173.3334C166 220 160 214 160 206.6666V193.3334C160 186 166 180 173.3334 180H186.6666C194 180 200 186 200 193.3334zM200 146.6666C200 154 194 160 186.6666 160H173.3334C166 160 160 154 160 146.6666V133.3334C160 126 166 120 173.3334 120H186.6666C194 120 200 126 200 133.3334V146.6666zM8.926 112.83L60 146.807V100C60 77.908 77.9086 60 100 60H220C242.092 60 260 77.908 260 100V146.807L311.074 112.83C321.242 106.066 323.028 91.874 314.854 82.808L246.14 6.62C242.338 2.406 236.924 0 231.242 0H88.7574C83.0758 0 77.661 2.406 73.8602 6.62L5.1462 82.808C-3.0286 91.874 -1.2418 106.066 8.9262 112.83z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Server.defaultProps = {
    size: IconSize.STANDARD,
};
Server.displayName = `Blueprint5.Icon.Server`;
export default Server;
