/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const RotateCcw: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="rotate-ccw" ref={ref}  {...props}>
            <path
                d={isLarge ? "M365.866 208.203C371.076 202.1684 370.746 193.136 365.1080000000001 187.498L278.588 100.978C272.644 95.034 272.644 85.4 278.588 79.458C284.53 73.514 294.164 73.514 300.108 79.456L386.628 165.976C403.542 182.89 404.5320000000001 209.9892 388.902 228.093L322.592 304.8906H350.216C358.622 304.8906 365.434 311.7036 365.434 320.108C365.434 328.5122 358.622 335.3254 350.216 335.3254H289.348C280.944 335.3254 274.13 328.5122 274.13 320.108V259.2384C274.13 250.834 280.944 244.021 289.348 244.021C297.752 244.021 304.564 250.834 304.564 259.2384V279.1988L365.866 208.203zM257.322 217.6776C267.084 207.9144 267.084 192.086 257.322 182.322L151.2558 76.256C141.4928 66.494 125.6636 66.494 115.9006 76.256L9.83454 182.322C0.0714306 192.086 0.0714306 207.9144 9.83454 217.6776L115.9006 323.7436C125.6636 333.5066 141.4928 333.5066 151.2558 323.7436L257.322 217.6776z" : "M292.692 166.5626C296.862 161.7348 296.596 154.5084 292.086 149.9982L222.87 80.782C218.116 76.028 218.116 68.32 222.87 63.566C227.624 58.812 235.332 58.812 240.086 63.566L309.304 132.7816C322.834 146.312 323.626 167.9916 311.122 182.4746L258.074 243.9126H280.174C286.898 243.9126 292.348 249.3632 292.348 256.0866C292.348 262.81 286.898 268.2604 280.174 268.2604H231.478C224.756 268.2604 219.304 262.81 219.304 256.0866V207.391C219.304 200.6674 224.756 195.217 231.478 195.217C238.202 195.217 243.652 200.6674 243.652 207.391V223.3594L292.692 166.5626zM205.858 174.1422C213.668 166.3318 213.668 153.6684 205.858 145.858L121.0054 61.006C113.195 53.194 100.5316 53.194 92.7212 61.006L7.86836 145.858C0.0578768 153.6684 0.0578768 166.3318 7.86836 174.1422L92.7212 258.995C100.5316 266.8056000000001 113.195 266.8056000000001 121.0054 258.995L205.858 174.1422z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
RotateCcw.defaultProps = {
    size: IconSize.STANDARD,
};
RotateCcw.displayName = `Blueprint5.Icon.RotateCcw`;
export default RotateCcw;
