/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const RotateCw: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="rotate-cw" ref={ref}  {...props}>
            <path
                d={isLarge ? "M34.1344 208.203C28.9238 202.1684 29.2544 193.136 34.892 187.498L121.4124 100.978C127.3552 95.034 127.3552 85.4 121.4126 79.458C115.4698 73.514 105.8346 73.514 99.8918 79.456L13.37148 165.976C-3.5415 182.89 -4.53324 209.9892 11.09828 228.093L77.408 304.8906H49.7826C41.3782 304.8906 34.5652 311.7036 34.5652 320.108C34.5652 328.5122 41.3782 335.3254 49.7826 335.3254H110.6522C119.0566 335.3254 125.8696 328.5122 125.8696 320.108V259.2384C125.8696 250.834 119.0566 244.021 110.6522 244.021C102.2478 244.021 95.4348 250.834 95.4348 259.2384V279.1988L34.1344 208.203zM382.322 217.6776C392.086 207.9144 392.086 192.086 382.322 182.322L276.256 76.256C266.4940000000001 66.494 250.664 66.494 240.9 76.256L134.835 182.322C125.0718 192.086 125.0718 207.9144 134.835 217.6776L240.9 323.7436C250.664 333.5066 266.4940000000001 333.5066 276.256 323.7436L382.322 217.6776z" : "M27.3074 166.5626C23.139 161.7348 23.4036 154.5084 27.9136 149.9982L97.13 80.782C101.8842 76.028 101.8842 68.32 97.13 63.566C92.3758 58.812 84.6678 58.812 79.9136 63.566L10.69718 132.7816C-2.8332 146.312 -3.6266 167.9916 8.87862 182.4746L61.9264 243.9126H39.826C33.1026 243.9126 27.6522 249.3632 27.6522 256.0866C27.6522 262.81 33.1026 268.2604 39.826 268.2604H88.5218C95.2452 268.2604 100.6956 262.81 100.6956 256.0866V207.391C100.6956 200.6674 95.2452 195.217 88.5218 195.217C81.7982 195.217 76.3478 200.6674 76.3478 207.391V223.3594L27.3074 166.5626zM305.858 174.1422C313.668 166.3318 313.668 153.6684 305.858 145.858L221.006 61.006C213.194 53.194 200.532 53.194 192.7208 61.006L107.868 145.858C100.0574 153.6684 100.0574 166.3318 107.868 174.1422L192.7208 258.995C200.532 266.8056000000001 213.194 266.8056000000001 221.006 258.995L305.858 174.1422z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
RotateCw.defaultProps = {
    size: IconSize.STANDARD,
};
RotateCw.displayName = `Blueprint5.Icon.RotateCw`;
export default RotateCw;
