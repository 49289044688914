/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Hexagon: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="hexagon" ref={ref}  {...props}>
            <path
                d={isLarge ? "M146.9992 300H253L308.4 200L253 100H146.9992L91.5992 200L146.9992 300zM169.9992 200C169.9992 183.4 183.3992 170 199.9992 170C216.6 170 230 183.4 230 200S216.6 230 199.9992 230C183.3992 230 169.9992 216.6 169.9992 200zM92.5988 369.8C96.1988 376 102.9988 380 110.3988 380H289.398C296.798 380 303.598 376.2 307.1980000000001 369.8L397.598 209.8C400.9980000000001 203.8 400.9980000000001 196.4 397.598 190.4L307.398 30.4C303.798 24.2 296.998 20.1999999999999 289.598 20.1999999999999H110.5988C103.1988 20.1999999999999 96.3988 24 92.7988 30.4L2.5988 190.4C-0.8012 196.4 -0.8012 203.8 2.5988 209.8L92.5988 369.8zM122.5988 60H277.798L356.798 200L277.798 340H122.5988L43.5988 200L122.5988 60z" : "M72.5834 30.078L2.6862 150.0772C-0.8954 156.2258 -0.8954 163.7742 2.6862 169.9228L72.5834 289.9228C76.213 296.1542 82.9682 300 90.284 300H229.716C237.032 300 243.786 296.1542 247.416 289.9228L317.314 169.9228C320.896 163.7742 320.896 156.2258 317.314 150.0772L247.416 30.078C243.786 23.846 237.032 20 229.716 20H90.284C82.968 20 76.2132 23.846 72.5834 30.078zM200.138 90H119.8612L79.0878 160L119.8612 230H200.138L240.912 160L200.138 90zM102.1148 60H217.886L276.132 160L217.886 260H102.1148L43.8672 160L102.1148 60zM160 140C171.2592 140 180.3868 148.9544 180.3868 160S171.2592 180 160 180C148.7408 180 139.6132 171.0456 139.6132 160S148.7408 140 160 140z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Hexagon.defaultProps = {
    size: IconSize.STANDARD,
};
Hexagon.displayName = `Blueprint5.Icon.Hexagon`;
export default Hexagon;
