/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Panel: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="panel" ref={ref}  {...props}>
            <path
                d={isLarge ? "M220 60L340 60L340 340L220 340L220 60zM20 0L380 0C391 0 400 9 400 20L400 380C400 391 391 400.0000007868061 380 400.000001748456L20 400.0000332206C9.00004 400.0000341824 0.0000341822 391.00004 0.0000332206 380.00004L0.000001748438 20C7.86786e-7 9 9 0 20 0zM40 360L360 360L360 40L40 40L40 360z" : "M20 0L300 0C311 0 320 9 320 20L320 300C320 311 311 320.0000007868061 300 320.000001748456L20 320.0000262268C9.00002 320.0000271884 0.0000271884 311.00002 0.0000262268 300.00002L0.000001748456 20C7.86804e-7 9 9 0 20 0zM40 280L280 280L280 40L40 40L40 280zM180 60L260 60L260 260L180 260L180 60z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Panel.defaultProps = {
    size: IconSize.STANDARD,
};
Panel.displayName = `Blueprint5.Icon.Panel`;
export default Panel;
