/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const FilterSortDesc: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="filter-sort-desc" ref={ref}  {...props}>
            <path
                d={isLarge ? "M400 360C400 371 391 380 380 380H120C109 380 100 371 100 360C100 354.4 102.2 349.4 105.8 345.8L200 251.8V80C200 69 209 60 220 60C225.6 60 230.6 62.2 234.2 65.8L294.2000000000001 125.8C297.8 129.4 300 134.4 300 140V251.8L394.2000000000001 346C397.8 349.4 400 354.4 400 360zM94.2 5.8C90.6 2.2 85.6 0 80 0C74.4 0 69.4 2.2 65.8 5.8L5.8 65.8C2.2 69.4 0 74.4 0 80C0 91 9 100 20 100C25.6 100 30.6 97.8 34.2 94.2000000000001L60 68.2V180C60 191 69 200 80 200C91 200 100 191 100 180V68.2L125.8 94C129.4 97.8 134.4 100 140 100C151 100 160 91 160 80C160 74.4 157.8 69.4 154.2 65.8L94.2 5.8z" : "M320 280C320 274.4 317.8 269.4 314.2000000000001 266L240 191.8V120C240 114.4 237.8 109.4 234.2 105.8L194.2 65.8C190.6 62.2 185.6 60 180 60C169 60 160 69 160 80V191.8L85.8 265.8C82.2 269.4 80 274.4 80 280C80 291 89 300 100 300H300C311 300 320 291 320 280zM100 80.098C94.4 80.098 89.4 77.9 85.8 74.304L80 68.512V140.0226C80 151.0088 71 159.9976 60 159.9976C49 159.9976 40 151.0088 40 140.0226V68.312L34.2 74.104C30.6 77.7 25.6 79.898 20 79.898C9 79.898 0 70.908 0 59.922C0 54.33 2.2 49.336 5.8 45.74L45.8 5.79C49.4 2.194 54.4 -0.002 60 -0.002C65.6 -0.002 70.6 2.194 74.2 5.79L114.2 45.74C117.8 49.336 120 54.33 120 59.922C120 71.108 111 80.098 100 80.098z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
FilterSortDesc.defaultProps = {
    size: IconSize.STANDARD,
};
FilterSortDesc.displayName = `Blueprint5.Icon.FilterSortDesc`;
export default FilterSortDesc;
