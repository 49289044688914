/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const WrenchSnooze: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="wrench-snooze" ref={ref}  {...props}>
            <path
                d={isLarge ? "M40 380H120C131.0456 380 140 371.0456 140 360C140 355.2048 138.3124 350.8036 135.4986 347.3582L81.6138 280H120C131.0456 280 140 271.0456 140 260S131.0456 240 120 240H40C28.9544 240 20 248.9544 20 260C20 264.8344 21.7152 269.2680000000001 24.5702 272.7258L78.388 340H40C28.9544 340 20 348.9544 20 360S28.9544 380 40 380zM270.002 80C253.58 80 237.998 83.598 224.002 90.052L165.124 31.174C150.226 16.276 126.0714 16.276 111.1734 31.174C96.2754 46.072 96.2756 70.226 111.1736 85.124L170.052 144.002C163.6008 157.998 160.0024 173.578 160.0024 190C160.0024 250.7514 209.252 300 270.002 300C289.476 300 307.7680000000001 294.9394 323.636 286.0616L248.788 211.2142C237.072 199.498 237.072 180.504 248.788 168.788C260.504 157.072 279.498 157.072 291.214 168.788L366.062 243.6358C374.942 227.7684000000001 380.002 209.4752 380.002 190C380.002 129.2480000000001 330.754 80.0000000000001 270.002 80.0000000000001z" : "M20 320H100C111.0456 320 120 311.0458 120 300C120 295.2048 118.3124 290.8036 115.4986 287.3582L61.6138 220H100C111.0456 220 120 211.0456 120 200S111.0456 180 100 180H20C8.9542 180 0 188.9544 0 200C0 204.8344 1.7152 209.268 4.5702 212.7258L58.388 280H20C8.9542 280 0 288.9544 0 300C0 311.0458 8.9542 320 20 320zM191.6926 68.532L132.0992 8.938C120.1808 -2.98 100.8572 -2.98 88.9388 8.938C77.0204 20.858 77.0204 40.18 88.9388 52.1L148.5324 111.692C132.846 145.0576 138.7886 186.0678 166.3604 213.6396C194.0696 241.3488 235.3520000000001 247.2128 268.8060000000001 231.2314L208.786 171.2126C197.071 159.497 197.071 140.502 208.786 128.7862C220.5020000000001 117.07 239.4980000000001 117.07 251.214 128.7862L311.2320000000001 188.8048C327.2120000000001 155.351 321.348 114.0700000000001 293.64 86.36C266.068 58.788 225.058 52.8460000000001 191.6926 68.532z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
WrenchSnooze.defaultProps = {
    size: IconSize.STANDARD,
};
WrenchSnooze.displayName = `Blueprint5.Icon.WrenchSnooze`;
export default WrenchSnooze;
