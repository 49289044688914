/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const LinkedSquares: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="linked-squares" ref={ref}  {...props}>
            <path
                d={isLarge ? "M22 380C9.8496 380 0 370.1502 0 358V142C0 129.85 9.84974 120 22 120H60C71.0456 120 80 128.954 80 140C80 151.046 71.0456 160 60 160H40V340H260V160H180C168.9544 160 160 151.046 160 140C160 128.954 168.9544 120 180 120H278C290.15 120 300 129.85 300 142V358C300 370.1504 290.15 380 278 380H22zM122 20C109.8496 20 100 29.85 100 42V258C100 270.1502 109.8498 280 122 280H220C231.046 280 240 271.0458 240 260C240 248.9542 231.046 240 220 240H140V60H360V240H340C328.954 240 320 248.9542 320 260C320 271.0458 328.954 280 340 280H378C390.15 280 400 270.1502 400 258V42C400 29.85 390.15 20 378 20H122z" : "M40 260V140C51.0456 140 60 131.0456 60 120C60 108.954 51.0456 100 40 100H25C11.19288 100 0 111.192 0 125L0 275C0 288.807 11.19286 300 25 300H215C228.808 300 240 288.807 240 275V125C240 111.192 228.808 100 215 100H160C148.9544 100 140 108.954 140 120C140 131.0456 148.9544 140 160 140H200V260H40zM280 60V180C268.954 180 260 188.9544 260 200C260 211.0458 268.954 220 280 220H295C308.808 220 320 208.8072 320 195V45C320 31.192 308.808 20 295 20H105C91.1928 20 80 31.192 80 45V195C80 208.807 91.1928 220 105 220H160C171.0456 220 180 211.0458 180 200C180 188.9544 171.0456 180 160 180H120V60H280z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
LinkedSquares.defaultProps = {
    size: IconSize.STANDARD,
};
LinkedSquares.displayName = `Blueprint5.Icon.LinkedSquares`;
export default LinkedSquares;
