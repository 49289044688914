/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const FighterJet: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="fighter-jet" ref={ref}  {...props}>
            <path
                d={isLarge ? "M294.48 345.52A10 10 0 0 1 306.68 344.02L386.28 391.76C389.8400000000001 393.92 393.9 389.86 391.76 386.28L344.02 306.68A10 10 0 0 1 345.52 294.48L352.92 287.08A10 10 0 0 0 352.92 272.92L303.4 223.4A10 10 0 0 1 300.54 215.22L319.48 44.78A10 10 0 0 0 316.6 36.6L305.1 25.1A10 10 0 0 0 293.56 23.22L185.78 77.0999999999999A4 4 0 0 1 180 73.5399999999999V16.18A10 10 0 0 0 165.52 7.24L106.84 36.5799999999999A10 10 0 0 0 101.4999999999999 47.4999999999999L109.58 87.8999999999999A4 4 0 0 1 108.48 91.4999999999999L91.52 108.4999999999999A4 4 0 0 1 87.9 109.5799999999999L47.5 101.4999999999999A10 10 0 0 0 36.58 106.8199999999999L7.24 165.52A10 10 0 0 0 16.18 180H54.46A4 4 0 0 1 58.26 185.26L21.94 294.14A10 10 0 0 0 24.34 304.36L36.58 316.6A10 10 0 0 0 44.76 319.46L215.2 300.52A10 10 0 0 1 223.38 303.4L272.9 352.92A10 10 0 0 0 287.06 352.92z" : "M233.86 266.14A10 10 0 0 1 246.94 265.2L308.8 311.6C310.66 313 313 310.6600000000001 311.6 308.8L265.2 246.94A10 10 0 0 1 266.14 233.86L272.94 227.06A10 10 0 0 0 272.94 212.92L243.54 183.52A10 10 0 0 1 240.7 175.06L259.3 44.94A10 10 0 0 0 256.4600000000001 36.46L244.6600000000001 24.66A10 10 0 0 0 233.86 22.46L145.5 57.8A4 4 0 0 1 140.02 54.1V13.88A10 10 0 0 0 126.86 4.38L88.4 17.2A10 10 0 0 0 81.76 28.66L89.6 67.9A4 4 0 0 1 88.5 71.5L80 80L71.52 88.48A4 4 0 0 1 67.9 89.58L28.66 81.74A10 10 0 0 0 17.2 88.38L4.4 126.84A10 10 0 0 0 13.86 140H54.06A4 4 0 0 1 57.78 145.48L22.42 233.88A10 10 0 0 0 24.64 244.68L36.44 256.48A10 10 0 0 0 44.92 259.2800000000001L175.04 240.7A10 10 0 0 1 183.52 243.54L212.92 272.9400000000001A10 10 0 0 0 227.06 272.9400000000001z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
FighterJet.defaultProps = {
    size: IconSize.STANDARD,
};
FighterJet.displayName = `Blueprint5.Icon.FighterJet`;
export default FighterJet;
