import React, { Component } from 'react';

import { connect } from 'react-redux';
import uuid from 'uuid';

import {
	Button,
	Alert,
	Intent,
} from '@blueprintjs/core';

import moment from 'moment';

import { addInvoice, addInvoices, addAdvanceInvoiceConsumptions }                             from '../slices/BusinessSlice';
import { saveInvoice, loadInvoices, loadAdvanceInvoiceConsumptions }                          from '../api/Business';
import { saveDocumentAssociation, deleteDocumentAssociation as deleteDocumentAssociationApi } from '../api/Documents';
import { addDocumentAssociation, addDocumentAssociations, deleteDocumentAssociation }         from '../slices/DocumentSlice';

import EditInvoiceDialog                                                   from './business/EditInvoiceDialog';
import WaitFiscalVerificationDialog                                        from './business/WaitFiscalVerificationDialog';
import Reservation                                                         from './reservations/Reservation';
import InvoiceCreator                                                      from '../helpers/InvoiceCreator';
import DocumentsHelper                                                     from '../helpers/DocumentsHelper';
import DocumentSearchDialog                                                from './DocumentSearchDialog';
import InvoiceHelper                                                       from '../helpers/Invoice';

import { enqueueInvoiceOnA4PrinterQueue, enqueueInvoiceOnPosPrinterQueue } from '../slices/AppSlice';

class Documents extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.openEditInvoiceDialog            = this.openEditInvoiceDialog           .bind(this);
		this.closeEditInvoiceDialog           = this.closeEditInvoiceDialog          .bind(this);
		this.createInvoiceFromOriginal        = this.createInvoiceFromOriginal       .bind(this);
		this.openReservationDialog            = this.openReservationDialog           .bind(this);
		this.closeReservationDialog           = this.closeReservationDialog          .bind(this);
		this.openDocumentSearchDialog         = this.openDocumentSearchDialog        .bind(this);
		this.closeDocumentSearchDialog        = this.closeDocumentSearchDialog       .bind(this);
		this.addAndSaveDocumentAssociation    = this.addAndSaveDocumentAssociation   .bind(this);
		this.deleteAndSaveDocumentAssociation = this.deleteAndSaveDocumentAssociation.bind(this);
		
		this.state = {
			invoice_dialog_open:         false,
			invoice_dialog_item:         null,
			invoice_dialog_type:         '',
			invoice_dialog_advance_invoices_amounts: {},
			reservation_dialog_open:     false,
			reservation_dialog_item:     null,
			document_search_dialog_open: false,
			wait_fiscal_verification_dialog_open:       false,
			wait_fiscal_verification_dialog_id_invoice: null,
			confirm_remove_dialog_open: false,
			confirm_remove_dialog_id:   -1,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	openEditInvoiceDialog(type, item, advance_invoices_amounts) {
		advance_invoices_amounts = advance_invoices_amounts || {};
		
		this.setState({
			invoice_dialog_open: true,
			invoice_dialog_type: type,
			invoice_dialog_item: item,
			invoice_dialog_advance_invoices_amounts: advance_invoices_amounts,
		});
	}
	async closeEditInvoiceDialog(item, open_create_invoice_type, finish_invoice) {
		this.setState({
			invoice_dialog_open: false,
			invoice_dialog_item: null,
		});
		
		if (item !== undefined) {
			this.props.dispatch(addInvoice({
				item,
				token: this.props.token,
			}));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			const new_items = await saveInvoice(this.props.api_url, item, token, dispatch);
			if (new_items !== null) {
				if (new_items.invoices !== undefined) {
					dispatch(addInvoices(new_items.invoices));
				}
				if (new_items.document_associations !== undefined) {
					dispatch(addDocumentAssociations(new_items.document_associations));
				}
				if (new_items.advance_invoice_consumptions !== undefined) {
					dispatch(addAdvanceInvoiceConsumptions(new_items.advance_invoice_consumptions));
				}
				
				if (finish_invoice && this.props.document_types[item.invoice_type].fiscal_verification_enabled) {
					this.setState({
						wait_fiscal_verification_dialog_open:       true,
						wait_fiscal_verification_dialog_id_invoice: item.id_invoice,
					});
				}
			}
			
			if (open_create_invoice_type !== undefined) {
				setTimeout(() => {
					if (!this._ismounted) return;
					this.createInvoiceFromOriginal(item.id_invoice, open_create_invoice_type, [ item.id_invoice ]);
				}, 1000);
			}
		}
	}
	
	createInvoiceFromOriginal(ids, invoice_type, advance_id_invoices) {
		ids = ids === null ? [] : (Array.isArray(ids) ? ids : [ ids ]);
		advance_id_invoices = advance_id_invoices || [];
		
		let first_item = ids.length == 0 ? null : this.props.invoices[ids[0]];
		
		const amount_factor = invoice_type == 'credit-note' ? -1 : 1;
		
		const original_id_invoices              = [];
		const original_invoice_types            = [];
		const selected_advance_invoices_amounts = {};
		let   advance_payment_amount            = 0;
		let   invoice_amount                    = 0;
		let   items                             = [];
		let   payments                          = [];
		
		for (let i=0; i<advance_id_invoices.length; i++) {
			const item = this.props.invoices[advance_id_invoices[i]];
			
			const advance_amount = Math.max(0, parseFloat(item.advance_payment_amount) - parseFloat(item.used_advance_payment_amount));
			advance_payment_amount += advance_amount;
			
			if (advance_amount > 0) {
				selected_advance_invoices_amounts[item.id_invoice] = advance_amount;
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const item = this.props.invoices[ids[i]];
			
			if (original_id_invoices.indexOf(ids[i]) == -1) {
				original_id_invoices  .push(ids[i]);
				original_invoice_types.push(item.invoice_type);
			}
			
			invoice_amount += item.invoice_amount * amount_factor;
			
			items = [
				...items,
				...item.items.map(item_item => {
					return InvoiceCreator.CreateInvoiceItemFromInitialData({
						id_invoice_item:                         uuid.v4(),
						description:                             item_item.description,
						discount:                                item_item.discount,
						id_item:                                 item_item.id_item,
						id_item_price_list:                      item_item.id_item_price_list,
						id_tax_rate:                             item_item.id_tax_rate,
						item_measuring_unit_code:                item_item.item_measuring_unit_code,
						item_measuring_unit_decimal_digit_count: item_item.item_measuring_unit_decimal_digit_count,
						price:                                   item_item.price,
						quantity:                                item_item.quantity * amount_factor,
						tax_rate:                                item_item.tax_rate,
						approved_percent:                        item_item.approved_percent,
						item_type:                               item_item.item_type,
						item_ean:                                item_item.item_ean,
						item_title:                              item_item.item_title,
						ord:                                     item_item.ord,
						taxable:                                 item_item.taxable,
						item_internal_code:                      item_item.item_internal_code,
						id_warehouse: item_item.item_type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
							this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
								this.props.cash_registers[this.props.current_id_cash_register],
							this.props.warehouses
						),
					});
				}),
			];
			payments = [
				...payments,
				//...item.payments.map(item_payment => {
				//	return InvoiceCreator.CreateInvoicePaymentFromInitialData({
				//		id_invoice_payment: uuid.v4(),
				//		id_payment_type:    item_payment.id_payment_type,
				//		amount:             item_payment.amount,
				//		payment_date:       item_payment.payment_date,
				//	});
				//}),
			];
		}
		
		// create new document
		let payment_date       = null;
		let invoice_date       = null;
		let service_date_end   = null;
		let service_date_start = null;
		
		if (first_item !== null) {
			payment_date       = first_item.payment_date;
			invoice_date       = first_item.invoice_date;
			service_date_end   = first_item.service_date_end;
			service_date_start = first_item.service_date_start;
			
			if (invoice_type == 'credit-note' || invoice_type == 'prepayment-invoice' || invoice_type == 'cash-prepayment-invoice') {
				payment_date = new Date();
				invoice_date = new Date();
			}
			else if (invoice_type == 'invoice') {
				payment_date       = null;
				invoice_date       = new Date();
				service_date_end   = new Date();
				service_date_start = new Date();
			}
		}
		
		const new_item = InvoiceCreator.CreateInvoiceFromInitialData({
			id_invoice:         uuid.v4(),
			id_customer:        first_item === null ? null : first_item.id_customer,
			invoice_date,
			invoice_type,
			note:               '',
			payment_date,
			reference:          first_item === null ? null : first_item.invoice_number_internal,
			reservation:        first_item === null ? null : first_item.reservation,
			service_date_end,
			service_date_start,
			invoice_amount,
			id_cash_register:   this.props.current_id_cash_register,
			advance_payment_amount,
			items,
			payments,
			original_id_invoice:   original_id_invoices,
			original_invoice_type: original_invoice_types,
		});
		this.openEditInvoiceDialog(new_item.invoice_type, new_item, selected_advance_invoices_amounts);
	}
	
	openReservationDialog(item) {
		this.setState({
			reservation_dialog_open: true,
			reservation_dialog_item: item,
		});
	}
	closeReservationDialog() {
		this.setState({
			reservation_dialog_open: false,
			reservation_dialog_item: null,
		});
	}
	
	openDocumentSearchDialog() {
		this.setState({
			document_search_dialog_open: true,
		});
	}
	closeDocumentSearchDialog(id_document, document_type) {
		this.setState({
			document_search_dialog_open: false,
		});
		
		if (id_document !== undefined && document_type !== undefined) {
			this.addAndSaveDocumentAssociation(
				this.props.id_document,
				this.props.document_type,
				id_document,
				document_type
			);
			
			if (this.props.document_type == 'reservation-customer' && document_type != 'reservation') {
				this.addAndSaveDocumentAssociation(
					this.props.reservation_customers[this.props.id_document].id_reservation,
					'reservation',
					id_document,
					document_type
				);
			}
		}
	}
	
	async addAndSaveDocumentAssociation(
		source_id_document,
		source_type,
		target_id_document,
		target_type
	) {
		const item = {
			id_document_association: uuid.v4(),
			source_id_document,
			source_type,
			target_id_document,
			target_type
		};
		
		this.props.dispatch(addDocumentAssociation({
			item,
			token: this.props.token,
		}));
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		const new_item = await saveDocumentAssociation(this.props.api_url, item, token);
		if (new_item !== null) {
			dispatch(addDocumentAssociation({
				item: new_item,
				token,
			}));
		}
	}
	
	async deleteAndSaveDocumentAssociation(id_document_association) {
		await deleteDocumentAssociationApi(this.props.api_url, id_document_association, this.props.token);
		this.props.dispatch(deleteDocumentAssociation({
			id_document_association,
		}));
	}
	
	render() {
		let documents = Object.values(DocumentsHelper.GetDocuments(
			this.props.document_associations,
			this.props.id_document,
			this.props.deep ? 3 : 1
		));
		
		// remove duplicates
		const document_ids = [];
		documents = documents.filter(document => {
			if (document_ids.indexOf(document.id_document) > -1) return false;
			if (
				document.document_type != 'reservation' &&
				this.props.document_types !== undefined &&
				this.props.document_types[document.document_type] === undefined
			) return false;
			
			document_ids.push(document.id_document);
			return true;
		});
		
		const find_idx_by_id_document = (documents, id_document) => {
			for (let i=0; i<documents.length; i++) {
				if (documents[i].id_document == id_document) return i;
			}
			return -1;
		};
		
		(this.props.id_advance_invoice_consumption_by_id_advance_invoices[this.props.id_document] || []).forEach(id => {
			const advance_invoice_consumption = this.props.advance_invoice_consumptions[id];
			if (advance_invoice_consumption !== undefined) {
				const id_document      = advance_invoice_consumption.consumer_id_invoice;
				const from_id_document = advance_invoice_consumption.advance_id_invoice;
				
				const idx = find_idx_by_id_document(documents, id_document);
				if (idx > -1) {
					documents.splice(idx, 1);
				}
				
				documents.push({
					created: advance_invoice_consumption.created,
					document_type: 'advance-invoice-consumption',
					id_document,
					from_id_document,
				});
			}
		});
		(this.props.id_advance_invoice_consumption_by_id_consumer_invoices[this.props.id_document] || []).forEach(id => {
			const advance_invoice_consumption = this.props.advance_invoice_consumptions[id];
			if (advance_invoice_consumption !== undefined) {
				const id_document      = advance_invoice_consumption.advance_id_invoice;
				const from_id_document = advance_invoice_consumption.consumer_id_invoice;
				
				const idx = find_idx_by_id_document(documents, id_document);
				if (idx > -1) {
					documents.splice(idx, 1);
				}
				
				documents.push({
					created: advance_invoice_consumption.created,
					document_type: 'advance-invoice-consumption',
					id_document,
					from_id_document,
				});
			}
		});
		
		documents
			.sort((a, b) => { // sort by created, descending
				if (a.created < b.created) return  1;
				if (a.created > b.created) return -1;
				return 0;
			});
		
		const hide_customer = this.props.hide_customer !== undefined && this.props.hide_customer === true;
		
		return <div className='documents-list overflow-y-auto h-full'>
			<Alert
				cancelButtonText='Prekliči'
				confirmButtonText='Nadaljuj'
				canEscapeKeyCancel={true}
				canOutsideClickCancel={true}
				icon='warning-sign'
				intent={Intent.WARNING}
				isOpen={this.state.confirm_remove_dialog_open}
				onConfirm={() => {
					this.deleteAndSaveDocumentAssociation(this.state.confirm_remove_dialog_id);
					
					this.setState({
						confirm_remove_dialog_open: false,
						confirm_remove_dialog_id:   -1,
					});
				}}
				onCancel={() => {
					this.setState({
						confirm_remove_dialog_open: false,
						confirm_remove_dialog_id:   -1,
					});
				}}>
				Res želite odstraniti povezavo na ta dokument?
			</Alert>
			
			{!this.state.invoice_dialog_open ? null :
				<EditInvoiceDialog
					closeEditInvoiceDialog={this.closeEditInvoiceDialog}
					item={this.state.invoice_dialog_item}
					type={this.state.invoice_dialog_type}
					selected_advance_invoices_amounts={this.state.invoice_dialog_advance_invoices_amounts} />
			}
			
			{!this.state.reservation_dialog_open ? null :
				<Reservation
					closeReservation={this.closeReservationDialog}
					deleteReservation={null}
					reservation={this.state.reservation_dialog_item} />
			}
			
			{!this.state.document_search_dialog_open ? null :
				<DocumentSearchDialog
					closeDialog={this.closeDocumentSearchDialog} />
			}
			
			{!this.state.wait_fiscal_verification_dialog_open ? null :
				<WaitFiscalVerificationDialog
					closeWaitFiscalVerificationDialog={(print) => {
						this.setState({ wait_fiscal_verification_dialog_open: false });
						
						if (print) {
							const invoice = this.props.invoices[this.state.wait_fiscal_verification_dialog_id_invoice];
							if (this.props.general_settings.auto_print_type == 'a4') {
								this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang: 'sl', invoice }));
							}
							else if (this.props.general_settings.auto_print_type == 'pos') {
								this.props.dispatch(enqueueInvoiceOnPosPrinterQueue({ type: 'invoice', invoice }));
							}
						}
					}}
					id_invoice={this.state.wait_fiscal_verification_dialog_id_invoice} />
			}
			
			<div className={'documents-table floating-header ' + (hide_customer ? 'hide-customer' : '')}>
				<div className='documents-table-header-col col-1'>
					Datum
				</div>
				<div className='documents-table-header-col col-2'>
					Št. dokumenta
				</div>
				<div className='documents-table-header-col col-3'>
					Tip
				</div>
				{hide_customer ? null :
					<div className='documents-table-header-col col-4'>
						Stranka
					</div>
				}
				<div className='documents-table-header-col col-5'>
					Znesek
				</div>
				
				{this.props.document_type === undefined || this.props.document_type === null || this.props.document_type.length == 0 ? null :
					<Button
						small={true}
						minimal={true}
						intent='primary'
						className='add-document-button'
						onClick={this.openDocumentSearchDialog}
						icon='add' />
				}
			</div>
			<div className={'documents-table ' + (hide_customer ? 'hide-customer' : '')}>
				{documents.map(item => {
					let id_document   = item.id_document;
					let document_type = item.document_type;
					
					const is_direct = item.from_document == this.props.id_document;
					
					if (document_type == 'advance-invoice-consumption') {
						const invoice = this.props.invoices[id_document];
						if (invoice !== undefined) {
							document_type = invoice.invoice_type;
						}
					}
					
					let document_date            = null;
					let document_document_number = null;
					let document_click_handler   = null;
					let document_type_title      = document_type;
					let document_customer        = null;
					let document_amount          = null;
					
					if (document_type == 'cash-invoice' || document_type == 'invoice' || document_type == 'offer' ||
						document_type == 'prepayment-invoice' || document_type == 'credit-note') {
						const invoice_item = this.props.invoices[id_document];
console.log({ id_document, invoice_item });
						
						document_date            = invoice_item.invoice_date;
						document_document_number = invoice_item.invoice_number_internal;
						document_amount          = invoice_item.invoice_amount;
						
						document_type_title = 'račun';
						
						switch (document_type) {
							case 'cash-invoice':
								document_type_title = 'blagajniški račun';
								break;
							case 'offer':
								document_type_title = 'ponudba';
								break;
							case 'credit-note':
								document_type_title = 'dobropis';
								break;
							case 'prepayment-invoice':
								document_type_title = 'avansni račun';
								document_amount     = invoice_item.advance_payment_amount;
								break;
						}
						
						document_customer = invoice_item.id_customer === null ? null :
							this.props.customers[invoice_item.id_customer];
						
						const self = this;
						document_click_handler = () => {
							self.openEditInvoiceDialog(invoice_item.invoice_type, invoice_item);
						};
					}
					else if (document_type == 'reservation') {
						const reservation_item = this.props.reservations[id_document];
						
						document_date            = reservation_item.check_in;
						document_document_number = reservation_item.internal_code;
						document_type_title      = 'rezervacija';
						document_amount          = null;
						
						document_customer = reservation_item.id_customer === null ? null :
							this.props.customers[reservation_item.id_customer];
						
						const self = this;
						document_click_handler = () => {
							self.openReservationDialog(reservation_item);
						};
					}
					else if (document_type == 'activity-reservation') {
						const reservation_item = this.props.activity_reservations[id_document];
						
						document_date            = reservation_item.reservation_date;
						document_document_number = reservation_item.internal_code;
						document_type_title      = 'rezervacija akt.';
						document_amount          = null;
						
						document_customer = reservation_item.id_customer === null ? null :
							this.props.customers[reservation_item.id_customer];
						
						/*document_click_handler = () => {
							self.openReservationDialog(reservation_item);
						};*/
					}
					else if (document_type == 'delivery-note') {
						const delivery_note_item = this.props.delivery_notes[id_document];
						
						document_date            = delivery_note_item.document_date;
						document_document_number = delivery_note_item.document_number;
						document_type_title      = 'dobavnica';
						document_amount          = null;
						
						document_customer = delivery_note_item.id_customer === null ? null :
							this.props.customers[delivery_note_item.id_customer];
						
						const self = this;
						//document_click_handler = () => {
						//	self.openReservationDialog(delivery_noteitem);
						//};
					}
					else {
						return null;
					}
					
					return <React.Fragment key={'documents-' + (item.id_document_association || item.id_document)}>
						<div className='documents-table-col col-1'>
							{!is_direct ? null :
								<Button
									small={true}
									minimal={true}
									className='cell-button'
									onClick={() => {
										this.setState({
											confirm_remove_dialog_open: true,
											confirm_remove_dialog_id:   item.id_document_association,
										});
									}}
									icon='remove' />
							}
							
							{document_date === null ? null : moment(document_date).format('DD.MM.YYYY')}
						</div>
						<div className='documents-table-col col-2'>
							{document_click_handler === null ? document_document_number :
								<Button
									small={true}
									className={'cell-button'}
									onClick={document_click_handler}>
									{document_document_number}
								</Button>
							}
						</div>
						<div className='documents-table-col col-3'>
							{document_type_title}
						</div>
						{hide_customer ? null :
							<div className='documents-table-col col-4'>
								{document_customer === null ? null :
									(document_customer.type == 'natural' ?
										document_customer.surname + ' ' + document_customer.name
										:
										document_customer.company_name
									)
								}
							</div>
						}
						<div className='documents-table-col col-5 text-right'>
							{document_amount === null ? null :
								parseFloat(document_amount)
									.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) +
									' EUR'
							}
						</div>
					</React.Fragment>;
				})}
			</div>
		</div>;
	}
}

function mapStateToProps(state) {
	return {
		document_associations:                                  state.DocumentSlice.document_associations_id_documents,
		invoices:                                               state.BusinessSlice.invoices,
		advance_invoice_consumptions:                           state.BusinessSlice.advance_invoice_consumptions,
		id_advance_invoice_consumption_by_id_advance_invoices:  state.BusinessSlice.id_advance_invoice_consumption_by_id_advance_invoices,
		id_advance_invoice_consumption_by_id_consumer_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
		customers:                                              state.CodeTablesSlice.customers,
		reservations:                                           state.ReservationSlice.reservations,
		reservation_customers_by_id_reservations:               state.ReservationSlice.reservation_customers_by_id_reservations,
		reservation_customers:                                  state.ReservationSlice.reservation_customers,
		activity_reservations:                                  state.ReservationSlice.activity_reservations,
		api_url:                                                state.CodeTablesSlice.api_url,
		token:                                                  state.UserSlice.token,
		document_types:                                         state.DocumentSlice.document_types,
		general_settings:                                       state.SettingsSlice.general,
		current_id_cash_register:                               state.SettingsSlice.current_id_cash_register,
		cash_registers:                                         state.CashRegisterSlice.cash_registers,
		warehouses:                                             state.CodeTablesSlice.warehouses,
		delivery_notes:                                         state.WarehouseSlice.delivery_notes,
	};
}

export default connect(mapStateToProps)(Documents);
